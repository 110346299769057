import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Flex, theme, Tooltip } from "antd";
import CardSkeleton from "../../../../../../atom/CardSkeleton";
import DashboardCompare from "../../../../../../organism/DashboardCompare";
import ModalConfirmSaveProposal from "../ModalConfirmSaveProposal";
import ModalGoalSettings from "../../../../../goals/components/ModalGoalSettings";
import ModalLevelRelatedProducts from "../../../../components/ModalLevelRelatedProducts";
import ProposalGoalDashboard from "../ProposalGoalDashboard";

import StylesContainer from "./style";

import {
  DEFAULT_ADVISOR_FEE,
  DEFAULT_BIRTH_YEAR,
  DEFAULT_RETIREMENT_AGE,
  DEFAULT_START_WITHDRAWAL_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../../../utils/constant";
import {
  PROPOSAL_DEFAULT_DATA_LEVEL,
  PROPOSAL_SAMPLE_URL_LEVEL,
} from "../../../../constants";

import { getLiveAssessment } from "../../../../../../utils/request/regularApp";
import {
  calculateWithdrawalLevelFromWithdrawalAmount,
  convertCurrentAgeRange,
  convertInvestmentDurationRange,
  getLastSavedProposalProps,
  getManuallyUpdatedOAGoalRecommendedProduct,
  isDemoProposalUser,
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
} from "../../../../../../utils/helper/specialized";
import { cleanPercentValue } from "../../../../../../utils/helper/general";
import { getRecommendedProductId } from "./helpers";

import { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as IconClose } from "../../../../images/icon_close.svg";

const ViewProposalGoal = ({
  activeGoal,
  handleCloseGoal,
  handleDownloadProposal,
  productsList,
}) => {
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const [compareMode, setCompareMode] = useState(false);
  const [confirmSaveDialogOpened, setConfirmSaveDialogOpened] = useState(false);
  const [proposalData, setProposalData] = useState(null);
  const [proposalDirty, setProposalDirty] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);

  useEffect(() => {
    if (!state.loading && state._id) {
      if (!proposalData) {
        const prospectObjective = state.getPreferenceValue("prospectObjective");
        const productMap = state.getPreferenceValue("productMap");

        if (activeGoal && productMap) {
          const activeGoalLastSavedProps =
            productMap[activeGoal][productMap[activeGoal].length - 1];

          setProposalData({
            ...activeGoalLastSavedProps,
            birthYear: activeGoalLastSavedProps.currentAge
              ? undefined
              : activeGoalLastSavedProps.birthYear,
            contributions:
              activeGoalLastSavedProps.contributions ??
              activeGoalLastSavedProps.savingsEachMonth * 12,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              activeGoalLastSavedProps.withdrawalLevel > 100
                ? Math.round(
                    (activeGoalLastSavedProps.withdrawalLevel /
                      activeGoalLastSavedProps.investmentAmount) *
                      100
                  )
                : activeGoalLastSavedProps.withdrawalLevel,
          });
        } else if (productMap) {
          const lastSavedProposalProps = getLastSavedProposalProps(productMap);
          const goalInitialData =
            prospectObjective ?? state.getPreferenceValue("guideInitialData");

          setProposalData({
            ...lastSavedProposalProps,
            ...goalInitialData,
            birthYear: goalInitialData?.currentAge
              ? undefined
              : (goalInitialData?.birthYear ??
                goalInitialData?.yearBorn ??
                DEFAULT_BIRTH_YEAR),
            lengthOfInvestment: undefined,
            retirementAge:
              goalInitialData?.retirementAge ?? DEFAULT_RETIREMENT_AGE,
            timestamp: undefined,
            withdrawalLevel:
              lastSavedProposalProps.withdrawalLevel > 100
                ? Math.round(
                    (lastSavedProposalProps.withdrawalLevel /
                      lastSavedProposalProps.investmentAmount) *
                      100
                  )
                : lastSavedProposalProps.withdrawalLevel,
          });
        } else if (prospectObjective) {
          const investmentDuration = isNaN(prospectObjective.investmentDuration)
            ? convertInvestmentDurationRange(
                prospectObjective.investmentDuration
              )
            : +prospectObjective.investmentDuration;

          const currentAge =
            prospectObjective.currentAge ??
            convertCurrentAgeRange(prospectObjective.currentAgeRange);

          setProposalData({
            ...prospectObjective,
            advisorFee: prospectObjective.advisorFee ?? DEFAULT_ADVISOR_FEE,
            birthYear: currentAge
              ? undefined
              : prospectObjective.birthYear || DEFAULT_BIRTH_YEAR,
            currentAge,
            incomeChartType: isGrowthTypeRiskTolerance(
              prospectObjective.investmentObjective
            )
              ? "contributions"
              : "income",
            investmentDuration,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              prospectObjective.withdrawalLevel > 100
                ? calculateWithdrawalLevelFromWithdrawalAmount({
                    contributions: prospectObjective.contributions,
                    fee: cleanPercentValue(prospectObjective.advisorFee),
                    investmentAmount: prospectObjective.investmentAmount,
                    riskTolerance: prospectObjective.riskTolerance,
                    withdrawalAmount: prospectObjective.withdrawalLevel,
                    yearToStartWithdrawal:
                      prospectObjective.yearToStartWithdrawals ??
                      Math.round(investmentDuration / 2),
                  })
                : (prospectObjective.withdrawalLevel ??
                  DEFAULT_WITHDRAWAL_LEVEL),
            yearToStartWithdrawals:
              prospectObjective.yearToStartWithdrawals ||
              Math.round(investmentDuration / 2) ||
              DEFAULT_START_WITHDRAWAL_YEAR,
          });
        } else {
          // onboarding quiz skipped
          setProposalData(PROPOSAL_DEFAULT_DATA_LEVEL);
        }
      }
    }
  }, [state]);

  useEffect(() => {
    if (proposalData) {
      const recommendedProductId =
        state.selectedProduct ??
        proposalData.recommendedProductId ??
        proposalData.productId ??
        getRecommendedProductId({
          organization: state.organization?.name ?? state.orgName,
          productsList,
          proposalData,
        });

      if (recommendedProductId) {
        setState(lastState => ({
          ...lastState,
          selectedProduct: recommendedProductId,
          compareProducts: [recommendedProductId],
        }));
      }
    }
  }, [productsList, proposalData, state.proposalViewMode]);

  useEffect(() => {
    if (!activeGoal) {
      setProposalDirty(true);
    }
  }, [activeGoal]);

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleProposalDataChange = useDebouncedCallback((values, skipDirty) => {
    if (!isLockedByAdminView(state.showWarning)) {
      !skipDirty && setProposalDirty(true);

      setProposalData(lastState => ({
        ...lastState,
        ...values,
      }));
    }
  }, 1000);

  const handleSaveProposal = () => {
    state.setKeyValue("loading", true);

    getLiveAssessment({
      objective: {
        ...proposalData,
        email: state._id,
        investmentDuration:
          proposalData?.investmentDuration ?? proposalData?.lengthOfInvestment,
        productId: state.selectedProduct,
        timestamp: undefined,
      },
      organization: state.organization?.name?.toLowerCase(),
      productId: state.selectedProduct,
      saveAction: activeGoal ? "PUT" : "POST",
      saveId: activeGoal,
      activeGoal,
    })
      .then(() => {
        setProposalDirty(false);
        state.showSuccess("Proposal saved");
        setConfirmSaveDialogOpened(false);
        setTimeout(() => {
          state.setUserData().then(data => {
            if (activeGoal) {
              //update existed active goal
              if (data.preferences?.valueMap?.productMap) {
                setProposalData(
                  data.preferences.valueMap.productMap[activeGoal][
                    data.preferences.valueMap.productMap[activeGoal].length - 1
                  ]
                );
              }
            } else {
              //set new created goal ID as active goal
              const createdGoalId = Object.keys(
                data.preferences.valueMap.productMap
              )[Object.keys(data.preferences.valueMap.productMap).length - 1];

              state.setKeyValue("activeGoal", createdGoalId);
              setProposalData(
                data.preferences.valueMap.productMap[createdGoalId][
                  data.preferences.valueMap.productMap[createdGoalId].length - 1
                ]
              );
            }
            state.setKeyValue("loading", false);
          });
        }, 1000);
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error);
      });
  };

  const handleUpdateGoal = updatedProposalData => {
    setProposalData(updatedProposalData);
    state.closeModal("goalSettingsModal");
    state.showSuccess("Goal settings updated");
    setProposalDirty(true);

    const recommendedProduct = getManuallyUpdatedOAGoalRecommendedProduct({
      customRiskTolerance: updatedProposalData.riskTolerance,
      currentSelectedProduct: productsList.find(
        it => it._id === state.selectedProduct
      ),
      productsList,
    });

    if (recommendedProduct) {
      setState(lastState => ({
        ...lastState,
        compareProducts: [recommendedProduct._id],
        selectedProduct: recommendedProduct._id,
      }));
    }
  };

  const handleSelectFinancialProduct = productData => {
    if (!productData) return;

    state.setKeyValue("openModalRelatedProducts", false);

    handleProposalDataChange({
      ...proposalData,
      riskTolerance: productData.riskTolerance,
    });

    state.setKeyValue("selectedProduct", productData._id);
    state.setKeyValue("compareProducts", [productData._id]);
  };

  const handleAddToCompare = productData => {
    if (!productData) return;

    if (state.compareProducts?.includes(productData._id)) {
      state.showWarning("Product already added to the Compare.");
      return;
    }

    state.setKeyValue("openModalRelatedProducts", false);
    state.setKeyValue("compareProducts", [
      ...state.compareProducts,
      productData._id,
    ]);

    setIsCompareChartDirty(true);
  };

  const handleDownloadProposalClick = () => {
    handleDownloadProposal(state.activeGoal);
  };

  return (
    <StylesContainer token={token}>
      <header>
        <div className="title">
          {compareMode ? (
            "Compare Products"
          ) : (
            <span>
              Level: <b>Investment Proposal</b>
            </span>
          )}
        </div>
        {compareMode ? (
          <Flex align="end">
            <Button
              icon={<IconClose />}
              onClick={() => setCompareMode(false)}
              shape="round"
            >
              Exit Compare Mode
            </Button>
          </Flex>
        ) : (
          !isDemoProposalUser(state._id) && (
            <Flex align="end" gap={22} wrap="wrap">
              {!proposalDirty && proposalData?.timestamp ? (
                <>
                  <Flex align="end" vertical>
                    <span style={{ fontSize: 12, color: token.color_grey_1 }}>
                      Created
                    </span>
                    <span style={{ fontSize: 12, color: token.color_black }}>
                      {dayjs(proposalData.timestamp).format("MM/DD/YYYY h:mma")}
                    </span>
                  </Flex>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={handleDownloadProposalClick}
                    shape="round"
                  >
                    Download Proposal
                  </Button>
                  <Button
                    icon={<IconClose />}
                    onClick={handleCloseGoal}
                    shape="round"
                    type="primary"
                  >
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <Tooltip title="Please save the proposal before closing">
                    <Button
                      disabled={true}
                      icon={<IconClose />}
                      onClick={handleCloseGoal}
                      shape="round"
                    >
                      Close
                    </Button>
                  </Tooltip>
                  <Button
                    disabled={isLockedByAdminView({
                      managerAccess: state.managerAccess,
                    })}
                    onClick={() => setConfirmSaveDialogOpened(true)}
                    shape="round"
                    type="primary"
                  >
                    Save Proposal
                  </Button>
                </>
              )}
            </Flex>
          )
        )}
        {isDemoProposalUser(state._id) && (
          <Button
            icon={<DownloadOutlined />}
            onClick={() => window.open(PROPOSAL_SAMPLE_URL_LEVEL)}
            shape="round"
            style={{
              background: token.tenant_color_primary,
              color: "#FFFFFF",
              borderColor: "transparent",
            }}
          >
            Download Proposal
          </Button>
        )}
      </header>

      <main>
        <CardSkeleton loading={state.loading}>
          {compareMode ? (
            <DashboardCompare
              closeCompareMode={() => setCompareMode(false)}
              handleCardClick={handleCardClick}
              isCompareChartDirty={isCompareChartDirty}
              openProductsListModal={() =>
                state.setKeyValue("openModalRelatedProducts", true)
              }
              productsList={productsList}
              proposalData={proposalData}
              setIsCompareChartDirty={setIsCompareChartDirty}
              setState={setState}
              showError={state.showError}
              showSecondaryButton={true}
              state={state}
            />
          ) : (
            <ProposalGoalDashboard
              activeGoal={activeGoal}
              handleCardClick={handleCardClick}
              handleProposalDataChange={handleProposalDataChange}
              openCompareMode={() => setCompareMode(true)}
              productsList={productsList}
              proposalData={proposalData}
            />
          )}
        </CardSkeleton>
      </main>

      <ModalConfirmSaveProposal
        handleClose={() => setConfirmSaveDialogOpened(false)}
        handleSaveProposal={handleSaveProposal}
        isFirstSave={
          !(state.getPreferenceValue("productMap") ?? [])[activeGoal]
        }
        open={confirmSaveDialogOpened}
      />
      <ModalGoalSettings
        handleClose={() => state.closeModal("goalSettingsModal")}
        handleUpdateGoal={handleUpdateGoal}
        loading={state.loading}
        open={state.goalSettingsModal}
        organization={state.organization?.name}
        proposalData={proposalData}
      />
      <ModalLevelRelatedProducts
        handleCardClick={handleCardClick}
        handleClose={() => state.setKeyValue("openModalRelatedProducts", false)}
        handleSelectFinancialProduct={
          compareMode ? handleAddToCompare : handleSelectFinancialProduct
        }
        open={state.openModalRelatedProducts}
      />
    </StylesContainer>
  );
};

export default ViewProposalGoal;
