import { Flex, Form, InputNumber, Select, theme } from "antd";

import StyledModal from "./style";

import { isPortalAdmin } from "../../../../../../utils/helper/specialized";

import Close from "../../../../../../icon/Close";

const DefaultProductAssumptionsModal = ({
  handleClose,
  investmentAssumptions,
  open,
}) => {
  const { token } = theme.useToken();

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      footer={false}
      onCancel={handleClose}
      open={open}
      title="Default Product Assumptions"
      width={415}
    >
      <p className="hero-section">
        {isPortalAdmin()
          ? "All products shown use the following defaults. The ability to change the defaults will be made available in the near future."
          : "All products shown use the following defaults."}
      </p>
      <Flex gap={16} vertical>
        <Form layout="vertical">
          <Form.Item label={token.proposal_copy_investment_amount}>
            <InputNumber
              readOnly={true}
              value={investmentAssumptions.investmentAmount}
            />
          </Form.Item>
          <Form.Item label="Length of Investment">
            <InputNumber
              addonAfter={
                <Form.Item style={{ marginBottom: 0 }}>
                  <Select
                    options={[
                      {
                        label: "Years",
                        value: "years",
                      },
                      {
                        disabled: true,
                        label: "Quarters",
                        value: "quarters",
                      },
                      {
                        disabled: true,
                        label: "Months",
                        value: "months",
                      },
                    ]}
                    style={{ width: 120 }}
                    value="years"
                  />
                </Form.Item>
              }
              readOnly={true}
              value={investmentAssumptions.investmentDuration}
            />
          </Form.Item>
          <Form.Item label="Annual Target Income">
            <InputNumber
              formatter={value => (value?.includes("%") ? value : `${value}%`)}
              max={100}
              min={1}
              parser={value => value.replace("%", "")}
              readOnly={true}
              value={investmentAssumptions.withdrawalLevel}
            />
          </Form.Item>
          <Form.Item label="Begin Withdrawal after">
            <InputNumber
              placeholder="N/A"
              readOnly={true}
              value={investmentAssumptions.yearToStartWithdrawals}
            />
          </Form.Item>
        </Form>
      </Flex>
    </StyledModal>
  );
};

export default DefaultProductAssumptionsModal;
