import { useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Input, Row, Space, Table, theme } from "antd";
import AddAdvisorModal from "./components/AddAdvisorModal";
import ButtonDemoMode from "../../../../../../atom/ButtonDemoMode";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalDeleteAdvisor from "../../../../component/ModalDeleteAdvisor";
import SectionAdvisorsAnalytic from "../SectionAdvisorsAnalytic";

import StylesContainer from "./style";

import {
  deleteAdvisor,
  openAdvisorView,
} from "../../../../../../utils/request/manager";
import { getCurrencyValue } from "../../../../../../utils/helper/general";
import {
  getLatestSavedProposalProps,
  getManagedUsers,
  getManagedUsersTotalProposedAmount,
  getProspectUsers,
  isAdminView,
} from "../../../../../../utils/helper/specialized";

import OpenInNew from "../../../../../../icon/OpenInNew";

const getItems = ({
  email,
  handleEditAction,
  handleDeleteAction,
  organization,
}) => [
  {
    key: "1",
    label: "Edit Advisor",
    onClick: () => handleEditAction(email),
  },
  {
    key: "2",
    label: (
      <Space>
        Open Advisor View
        <OpenInNew />
      </Space>
    ),
    onClick: () => openAdvisorView(email, organization),
  },
  {
    type: "divider",
  },
  {
    key: "3",
    label: <span style={{ color: "red" }}>Delete Advisor</span>,
    onClick: () => handleDeleteAction(email),
  },
];

const ViewAdvisors = ({ setActiveAdvisorEmail }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [deletedAdvisorEmail, setDeletedAdvisorEmail] = useState();
  const [editedAdvisorEmail, setEditedAdvisorEmail] = useState();

  const organization = state.organization?.name;

  const countSavedProposals = users =>
    users
      ?.filter(it => it.proposalSavedDate || it.valueMap?.productMap)
      ?.reduce((acc, it) => {
        if (it.valueMap?.productMap) {
          // accumulate quantity of saved goals
          return acc + Object.keys(it.valueMap.productMap).length;
        } else {
          // goal is only one, add it
          return ++acc;
        }
      }, 0) ?? 0;

  const advisorsTableData = useMemo(
    () =>
      state.managedUserManagers?.map(
        ({
          userManager: advisorData = {},
          firstName,
          lastName = " ",
          email,
        }) => {
          let latestProposalDate;

          advisorData.managedUsers?.map(it => {
            if (it.proposalSavedDate || it.valueMap?.productMap) {
              if (!latestProposalDate) {
                latestProposalDate =
                  it.proposalSavedDate?.$date ||
                  getLatestSavedProposalProps(it.valueMap?.productMap)
                    ?.timestamp;
              } else if (
                dayjs(
                  it.proposalSavedDate?.$date ||
                    getLatestSavedProposalProps(it.valueMap?.productMap)
                      ?.timestamp
                ).isAfter(latestProposalDate)
              ) {
                latestProposalDate =
                  it.proposalSavedDate?.$date ||
                  getLatestSavedProposalProps(it.valueMap?.productMap)
                    ?.timestamp;
              }
            }
          });

          return {
            advisor: firstName ? firstName + " " + lastName : lastName,
            brokerName: advisorData.brokerDealerName ?? "",
            clients: getManagedUsers(advisorData)?.length ?? 0,
            clientsProposals: countSavedProposals(getManagedUsers(advisorData)),
            email,
            firmName: advisorData.firmName ?? "",
            key: email,
            latestProposalDate: latestProposalDate
              ? dayjs(latestProposalDate).format("MM/DD/YYYY")
              : "-",
            proposedAum: getCurrencyValue(
              getManagedUsersTotalProposedAmount(
                advisorData.managedUsers?.filter(
                  it => it.proposalSavedDate || it.valueMap?.productMap
                )
              )
            ),
            prospects: getProspectUsers(advisorData)?.length ?? 0,
            prospectsProposals: countSavedProposals(
              getProspectUsers(advisorData)
            ),
            type: advisorData.userType ?? "",
          };
        }
      ),
    [state.managedUserManagers]
  );

  const handleEditAction = advisorEmail => {
    setEditedAdvisorEmail(advisorEmail);
    state.openModal("addAdvisorModal");
  };

  const handleDeleteAction = advisorEmail => {
    setDeletedAdvisorEmail(advisorEmail);
    state.openModal("deleteAdvisorModal");
  };

  const handleOpenAddAdvisor = () => {
    setEditedAdvisorEmail(null);
    state.openModal("addAdvisorModal");
  };

  const handleDeleteAdvisor = () => {
    state.setKeyValue("loading", true);

    deleteAdvisor(deletedAdvisorEmail)
      .then(response => {
        state.setAdminData().then(() => {
          state.showSuccess(response.data);
          state.closeModal("deleteAdvisorModal");
          setDeletedAdvisorEmail(null);
        });
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error);
      });
  };

  return (
    <StylesContainer>
      <SectionAdvisorsAnalytic />
      <Row justify="space-between" align="middle" style={{ margin: "28px 0" }}>
        <Input
          style={{
            maxWidth: 290,
            height: 37,
            borderRadius: 25,
          }}
          placeholder="Search Advisors..."
          onChange={event => setSearchedText(event.target.value)}
        />
        <Space size={15}>
          <ButtonDemoMode
            organization={state.organization?.name}
            userManagerEmail={state._id}
          />
          <Button
            disabled={state.loading || isAdminView()}
            onClick={handleOpenAddAdvisor}
            shape="round"
            size="small"
            style={{
              width: 118,
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
          >
            + Add Advisor
          </Button>
        </Space>
      </Row>
      <Table
        dataSource={advisorsTableData}
        pagination={false}
        loading={state.loading}
        scroll={{
          x: 1450,
        }}
      >
        <Table.Column
          dataIndex="advisor"
          filteredValue={[searchedText]}
          key="advisor"
          onFilter={(value, record) =>
            String(record.advisor)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.email).toLowerCase().includes(value.toLowerCase())
          }
          render={(text, restValues) => (
            <a onClick={() => setActiveAdvisorEmail(restValues.email)}>
              <b>{text}</b>
            </a>
          )}
          sorter={(a, b) => a.advisor.localeCompare(b.advisor)}
          title="Advisor"
        />
        <Table.Column
          dataIndex="email"
          key="email"
          render={value => <a href={`mailto:${value}`}>{value}</a>}
          sorter={(a, b) => a.email.localeCompare(b.email)}
          title="Email"
        />
        <Table.Column
          dataIndex="type"
          key="type"
          sorter={(a, b) => a.type.localeCompare(b.type)}
          title="Type"
        />
        <Table.Column
          dataIndex="firmName"
          key="firmName"
          render={(_, allValues) => allValues.brokerName || allValues.firmName}
          title="Firm Name"
        />
        <Table.Column
          className="cell-proposed-aum"
          dataIndex="proposedAum"
          key="proposedAum"
          sorter={(a, b) => a.proposedAum.localeCompare(b.proposedAum)}
          title="Proposed AUM"
        />
        <Table.Column
          dataIndex="clients"
          key="clients"
          sorter={(a, b) => a.clients - b.clients}
          title="Clients"
        />
        <Table.Column
          dataIndex="clientsProposals"
          key="clientsProposals"
          sorter={(a, b) => a.clientsProposals - b.clientsProposals}
          title="Client Proposals"
        />
        <Table.Column
          dataIndex="prospects"
          key="prospects"
          sorter={(a, b) => a.prospects - b.prospects}
          title="Prospects"
        />
        <Table.Column
          dataIndex="prospectsProposals"
          key="prospectsProposals"
          sorter={(a, b) => a.prospectsProposals - b.prospectsProposals}
          title="Prospect Proposals"
        />
        <Table.Column
          dataIndex="latestProposalDate"
          key="latestProposalDate"
          title="Latest Proposal"
        />
        <Table.Column
          className="actions-menu-table-cell"
          fixed="right"
          key="actionsMenu"
          render={(_, allValues) => (
            <MenuActions
              items={getItems({
                email: allValues.email,
                handleEditAction,
                handleDeleteAction,
                organization,
              })}
            />
          )}
          width={40}
        />
      </Table>

      <AddAdvisorModal
        open={state.addAdvisorModal}
        handleClose={() => state.closeModal("addAdvisorModal")}
        editedAdvisorEmail={editedAdvisorEmail}
      />
      <ModalDeleteAdvisor
        email={deletedAdvisorEmail}
        handleOk={handleDeleteAdvisor}
        loading={state.loading}
        onCancel={() => state.closeModal("deleteAdvisorModal")}
        open={state.deleteAdvisorModal}
      />
    </StylesContainer>
  );
};

export default ViewAdvisors;
