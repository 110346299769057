import { useContext, useEffect, useState } from "react";
import State from "../../../../context";
import { Button, Flex, Input, Table, Tag, theme } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FiltersContainer from "./components/FiltersContainer";
import ModalLevelProductDetails from "../ModalLevelProductDetails";

import StylesContainer from "./style";

import {
  DEFAULT_FILTER_ASSET_MANAGERS_LEVEL,
  FILTER_TAG_COLOR_LIST,
} from "../../constants";
import { getPercentValue } from "../../../../utils/helper/general";

import { SearchOutlined } from "@ant-design/icons";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";
import { ReactComponent as ActionMenu } from "./images/action_menu.svg";

const DashboardLevelProducts = ({ handleActionClick, handleCardClick }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchTerm, setSearchTerm] = useState("");
  const [assetManagerFilters, setAssetManagerFilters] = useState(
    DEFAULT_FILTER_ASSET_MANAGERS_LEVEL
  );
  const [customFilters, setCustomFilters] = useState([]);
  const [typeCategoryFilters, setTypeCategoryFilters] = useState([]);
  const [broadCategoryFilters, setBroadCategoryFilters] = useState([]);
  const [globalCategoryFilters, setGlobalCategoryFilters] = useState([]);
  const [productCategoryFilters, setProductCategoryFilters] = useState([]);
  const [productFiltersApplied, setProductFiltersApplied] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Set filtersApplied to true if any filters are applied
  useEffect(() => {
    if (
      searchTerm === "" &&
      customFilters.length === 0 &&
      typeCategoryFilters.length === 0 &&
      assetManagerFilters.length === 0 &&
      broadCategoryFilters.length === 0 &&
      globalCategoryFilters.length === 0 &&
      productCategoryFilters.length === 0
    ) {
      setProductFiltersApplied(false);
    } else {
      setProductFiltersApplied(true);
    }
    setFilteredProducts(getFilteredProducts());
  }, [
    state.productsList,
    searchTerm,
    typeCategoryFilters,
    assetManagerFilters,
    broadCategoryFilters,
    globalCategoryFilters,
    productCategoryFilters,
    customFilters,
  ]);

  const handleAssetManagerFilterChange = checkedValues => {
    setAssetManagerFilters(checkedValues);
  };

  const handleCustomFilterChange = checkedValues => {
    setCustomFilters(checkedValues);
  };

  const handleBroadCategoryFilterChange = checkedValues => {
    setBroadCategoryFilters(checkedValues);
  };

  const handleGlobalCategoryFilterChange = checkedValues => {
    setGlobalCategoryFilters(checkedValues);
  };

  const handleProductCategoryFilterChange = checkedValues => {
    setProductCategoryFilters(checkedValues);
  };

  const handleTypeCategoryFilterChange = checkedValues => {
    setTypeCategoryFilters(checkedValues);
  };

  const handleToggleFavorite = productId =>
    state.setKeyValue(
      "productsList",
      state.productsList.map(it => {
        if (it._id === productId) {
          const wasFavorite = it.customFilter === "My Favorites";

          state.showSuccess(
            wasFavorite ? "Removed from favorites" : "Added to favorites"
          );

          return {
            ...it,
            customFilter: wasFavorite ? null : "My Favorites",
          };
        } else {
          return it;
        }
      })
    );

  const applySearchFilter = products => {
    if (searchTerm === "") return products;
    return products?.filter(
      product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) || ""
    );
  };

  const applyFilter = (products, key, filters) => {
    if (filters.length === 0) return products;
    return products?.filter(product => filters.includes(product[key]));
  };

  const getFilteredProducts = () =>
    applyFilter(
      applyFilter(
        applyFilter(
          applyFilter(
            applyFilter(
              applyFilter(
                applySearchFilter(state.productsList),
                "assetManager",
                assetManagerFilters
              ),
              "broadCategory",
              broadCategoryFilters
            ),
            "globalCategory",
            globalCategoryFilters
          ),
          "productCategory",
          productCategoryFilters
        ),
        "type",
        typeCategoryFilters
      ),
      "customFilter",
      customFilters
    );

  const getColumns = () => [
    {
      render: (_, allValues) => (
        <a onClick={() => handleCardClick(allValues._id)}>{allValues.name}</a>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      title: "Holding",
    },
    {
      dataIndex: "ticker",
      sorter: (a, b) => (a.ticker ?? "").localeCompare(b.ticker ?? ""),
      title: "Ticker",
    },
    {
      align: "right",
      render: (_, allValues) =>
        getPercentValue(allValues.properties?.mcExpectedReturn),
      sorter: (a, b) =>
        a.properties?.mcExpectedReturn - b.properties?.mcExpectedReturn,
      title: "Return",
      width: 120,
    },
    {
      align: "right",
      render: (_, allValues) =>
        getPercentValue(allValues.properties?.mcVolatilityReturn),
      sorter: (a, b) =>
        a.properties?.mcVolatilityReturn - b.properties?.mcVolatilityReturn,
      title: "Risk Number",
      width: 140,
    },
    {
      dataIndex: "broadCategory",
      render: broadCategory => (
        <Tag
          color={
            FILTER_TAG_COLOR_LIST.find(it => it.label === broadCategory)?.color
          }
          style={{ height: 24 }}
        >
          {broadCategory}
        </Tag>
      ),
      sorter: (a, b) =>
        (a.broadCategory ?? "").localeCompare(b.broadCategory ?? ""),
      title: "Category",
    },
    {
      align: "right",
      render: (_, allValues) => (
        <Flex align="center" gap={16} justify="end">
          <Button
            icon={
              <FontAwesomeIcon
                icon={
                  allValues.customFilter === "My Favorites"
                    ? faBookmarkSolid
                    : faBookmarkRegular
                }
              />
            }
            onClick={() => handleToggleFavorite(allValues._id)}
            size="small"
            type="text"
          />
          <Button
            disabled={!handleActionClick}
            icon={<ActionMenu />}
            onClick={() => handleActionClick(allValues)}
            shape="circle"
            size="small"
            style={{ height: "fit-content", minHeight: "unset" }}
            type="text"
          />
        </Flex>
      ),
      title: "Action",
      width: 70,
    },
  ];

  return (
    <StylesContainer token={token}>
      <header>
        <h4>Products</h4>
      </header>

      <main>
        {!!state.productsList?.length && (
          <>
            <FiltersContainer
              customFilters={customFilters}
              productFiltersApplied={productFiltersApplied}
              assetManagerFilters={assetManagerFilters}
              broadCategoryFilters={broadCategoryFilters}
              globalCategoryFilters={globalCategoryFilters}
              productCategoryFilters={productCategoryFilters}
              typeCategoryFilters={typeCategoryFilters}
              handleCustomFilterChange={handleCustomFilterChange}
              handleAssetManagerFilterChange={handleAssetManagerFilterChange}
              handleBroadCategoryFilterChange={handleBroadCategoryFilterChange}
              handleGlobalCategoryFilterChange={
                handleGlobalCategoryFilterChange
              }
              handleProductCategoryFilterChange={
                handleProductCategoryFilterChange
              }
              handleTypeCategoryFilterChange={handleTypeCategoryFilterChange}
              productsList={state.productsList}
              filteredProducts={filteredProducts}
            />

            <Flex gap={16} style={{ width: "100%" }} vertical>
              <Input
                allowClear
                onChange={e => setSearchTerm(e.target.value)}
                placeholder="Search..."
                size="large"
                style={{ borderRadius: 20, width: 270 }}
                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
                value={searchTerm}
              />

              <Table
                columns={getColumns()}
                dataSource={filteredProducts?.map(it => ({
                  ...it,
                  key: it._id,
                }))}
                pagination={false}
                size="small"
              />
            </Flex>
          </>
        )}
      </main>
    </StylesContainer>
  );
};

export default DashboardLevelProducts;
