import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import { Flex } from "antd";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import { getGrowthChartConfig } from "../../../../../../utils/chart";
import { getPercentValue } from "../../../../../../utils/helper/general";

const SectionPlanning = ({ liveAssessment, loading, product }) => {
  const getMainChartSeries = (series = []) => {
    liveAssessment?.grandTotalColumn &&
      series.push({
        name: "Calculated progress",
        lineWidth: 2,
        color: "#537CF1",
        zoneAxis: "x",
        marker: {
          enabled: false,
        },
        data: liveAssessment.grandTotalColumn,
      });

    return series;
  };

  return (
    <>
      <ChartSkeleton loading={loading} height={332}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...getGrowthChartConfig({
              birthYear: product?.currentAge
                ? dayjs().format("YYYY") - product?.currentAge
                : product?.birthYear,
            }),
            series: getMainChartSeries(),
          }}
        />
      </ChartSkeleton>

      <h4>Planning Projection Statistics</h4>
      <Flex gap={16}>
        <div className="value-container">
          <span>Expected Return</span>
          <p>{getPercentValue(product?.properties?.mcExpectedReturn)}</p>
        </div>
        <div className="value-container">
          <span>Standard Deviation</span>
          <p>{getPercentValue(product?.properties?.mcVolatilityReturn)}</p>
        </div>
      </Flex>
    </>
  );
};

export default SectionPlanning;
