import { Button } from "antd";
import DashboardLevelProducts from "../DashboardLevelProducts";

import StyledModal from "./style";

const ModalRelatedProducts = ({
  handleCardClick,
  handleClose,
  handleSelectFinancialProduct,
  open,
}) => (
  <StyledModal
    closeIcon={
      <Button shape="round" style={{ height: 36 }} onClick={handleClose}>
        Close
      </Button>
    }
    footer={false}
    onCancel={handleClose}
    open={open}
    title="Related Products"
    width={1151}
  >
    <DashboardLevelProducts
      handleActionClick={handleSelectFinancialProduct}
      handleCardClick={handleCardClick}
    />
  </StyledModal>
);

export default ModalRelatedProducts;
