import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../context";
import { Button, Col, Dropdown, Layout, theme } from "antd";
import SectionHistorical from "./components/SectionHistorical";
import SectionOverview from "./components/SectionOverview";
import SectionSimulations from "./components/SectionSimulations";
import SectionXRay from "./components/SectionXRay";

import StyledModal, { StyledTabs } from "./style";

import {
  DEFAULT_PRODUCT_ACCENT_COLOR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../utils/constant";

import {
  getCalculations,
  getLiveAssessment,
} from "../../utils/request/regularApp";
import { getPercentValue } from "../../utils/helper/general";
import {
  isOrganizationGoals,
  isProposalUser,
} from "../../utils/helper/specialized";

import Close from "../../icon/Close";

const ModalProductDetails = ({
  actions,
  open,
  handleClose,
  product,
  productsList,
  investmentAssumptions,
  organization,
  xRayList,
  hideMcSettingsToggler,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });
  const [state] = useContext(State);
  const [liveAssessment, setLiveAssessment] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!product || !open || state.proposalViewMode === "pdf") {
      return;
    }

    setLoading(true);

    if (product?.productName === "initial") {
      getCalculations({
        currentScenario: "initial",
        incomeBarchart: true,
        client: (organization ?? isProposalUser(state)) ? "level2" : "goals",
      }).then(data => {
        setLoading(false);
        setLiveAssessment({
          ...data,
          productId: product._id,
        });
      });
    } else {
      const requestBody = {
        organization:
          organization?.toLowerCase() === "level"
            ? "level2"
            : organization?.toLowerCase(),
        productId: product._id ?? product.productId,
        objective: {
          ...investmentAssumptions,
          ...product,
          withdrawalLevel: getPercentValue(
            product.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL
          ),
        },
      };

      getLiveAssessment(requestBody)
        .then(data => {
          setLoading(false);
          setLiveAssessment({
            ...data,
            productId: product._id,
          });
        })
        .catch(error => {
          setLoading(false);
          state.showError(error);
        });
    }
  }, [product, open]);

  const items = [
    {
      children: product && (
        <SectionOverview
          closeModal={handleClose}
          investmentAssumptions={investmentAssumptions}
          liveAssessment={liveAssessment}
          loading={loading}
          product={product}
          productsList={productsList}
          viewMode={state.proposalViewMode}
        />
      ),
      key: "Overview",
      label: `Overview`,
      visible: true,
    },
    {
      children: product && xRayList && (
        <SectionXRay product={product} xRayList={xRayList} />
      ),
      key: "XRay",
      label: `Portfolio X-Ray`,
      visible: xRayList && product?.allocationType !== "ETF",
    },
    {
      children: <SectionHistorical product={product} />,
      key: "historical",
      label: "Historical",
      visible: product?.ticker === "SPY",
    },
    {
      children: product && (
        <SectionSimulations
          hideMcSettingsToggler={hideMcSettingsToggler}
          investmentAssumptions={investmentAssumptions}
          liveAssessment={liveAssessment}
          organization={organization}
          product={product}
        />
      ),
      key: "Simulations",
      label: `Simulations`,
      visible: !isOrganizationGoals(organization),
    },
  ].filter(it => it.visible);

  const handleMenuClick = ({ item }) => item.props.onSelect(product);

  return (
    <StyledModal
      open={open}
      onCancel={handleClose}
      footer={null}
      width={product?.goalId === "emergencySavings" ? 744 : 1030}
      closeIcon={<Close color={token.color_grey_1} size={24} />}
      style={{ top: 32 }}
    >
      <Layout.Header
        style={{
          background: token.modal_financial_product_details_header_background,
          borderLeft: `16px solid ${
            product?.color ?? DEFAULT_PRODUCT_ACCENT_COLOR
          }`,
        }}
      >
        <Col xs={18} sm={18} md={20} lg={20}>
          <div
            dangerouslySetInnerHTML={{
              __html: product?.assetManager ?? "Level®",
            }}
          />
          <h3 className="title">
            {product?.name ?? `${product?.riskTolerance} Growth Rate`}
          </h3>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4}>
          {actions?.length > 0 && (
            <Dropdown
              menu={{
                items: actions?.filter(
                  action => action.key !== "action-item-0"
                ),
                onClick: handleMenuClick,
              }}
              trigger={["click"]}
            >
              <Button
                className="modal-product-details-actions-menu-toggler"
                shape="round"
                type="text"
                size={isSmallScreen ? "medium" : "small"}
                style={{
                  background: token.colorWhite,
                }}
              >
                Options
              </Button>
            </Dropdown>
          )}
        </Col>
      </Layout.Header>
      <StyledTabs
        className="product-details-tabs"
        defaultActiveKey="Overview"
        items={items}
      />
    </StyledModal>
  );
};

export default ModalProductDetails;
