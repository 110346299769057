import { useContext, useMemo, useState } from "react";
import State from "../../../../context";
import QuestionnaireContentAstor from "../../../astor/components/QuestionnaireContentAstor";
import QuestionnaireContentLevel from "../../../level/components/QuestionnaireContentLevel";
import QuestionnaireContentIPQ from "../../../oneascent/component/QuestionnaireContentIPQ";
import QuestionnaireContentRivershares from "../../../rivershares/components/QuestionnaireContentRivershares";
import QuestionnaireContentRTQ from "../../../level/components/QuestionnaireContentRTQ";
import QuestionnaireContentWealth from "../../../oneascent/component/QuestionnaireContentWealth";
import ModalDisclosures from "./components/ModalDisclosures";

import StyledModal from "./style";

import { DEFAULT_BIRTH_YEAR } from "../../../../utils/constant";

import { openProposal } from "../../../../utils/request/manager";
import { updateProductObjective } from "../../../../utils/request/regularApp";
import {
  isOrganizationAstor,
  isOrganizationRivershares,
} from "../../../../utils/helper/specialized";

const ModalProposalGuideManager = ({ open, handleClose }) => {
  const [state] = useContext(State);
  const [showDisclosuresModal, setShowDisclosuresModal] = useState(false);
  const organization = state.organization?.name ?? state.orgName;

  const handleCreateProposal = proposalData => {
    state.setKeyValue("loading", true);
    handleClose();

    updateProductObjective({
      ...proposalData,
      email: state.activeEmail,
      birthYear:
        state.managedUsers?.find(it => it.email === state.activeEmail)
          ?.birthYear ||
        proposalData.birthYear ||
        DEFAULT_BIRTH_YEAR,
      riskTolerance:
        proposalData.riskTolerance === "unknown"
          ? "moderate"
          : proposalData.riskTolerance,
      secondQuizPartSkipped: false,
    })
      .then(() => {
        state.setAdminData().then(() => {
          state.showSuccess("New proposal created!");
          state.setKeyValue("loading", false);
          openProposal({
            userEmail: state.activeEmail,
            organization: proposalData.organization,
          });
        });
      })
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleIpqSaveContinueButton = ({ proposalData, callback }) => {
    state.setKeyValue("loading", true);

    saveUserGuideData(proposalData).then(() => {
      state.setAdminData().then(() => {
        state.showSuccess("Questionnaire data saved");
        callback && callback();
        state.setKeyValue("loading", false);
      });
    });
  };

  const handleIpqSaveExitButton = ({ proposalData, callback }) => {
    state.setKeyValue("loading", true);

    saveUserGuideData({
      ...proposalData,
      secondQuizPartSkipped: true,
    }).then(() => {
      state.setAdminData().then(() => {
        state.showSuccess("Questionnaire data saved");
        callback && callback();
        handleClose();
        state.setKeyValue("loading", false);
      });
    });
  };

  const saveUserGuideData = customProposalData => {
    const activeUserData = state.managedUsers?.find(
      it => it.email === state.activeEmail
    );

    return updateProductObjective({
      email: state.activeEmail,
      ...(activeUserData.valueMap.prospectObjective ?? {}),
      ...customProposalData,
    }).catch(error => {
      state.showError(error);
      state.setKeyValue("loading", false);
    });
  };

  const Content = useMemo(() => {
    if (state.onboardingQuestionnaireType === "wealthManagement")
      return () => (
        <QuestionnaireContentWealth
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
          setShowDisclosuresModal={setShowDisclosuresModal}
        />
      );
    else if (state.onboardingQuestionnaireType === "IPQ")
      return () => (
        <QuestionnaireContentIPQ
          handleCreateProposal={handleCreateProposal}
          handleIpqSaveContinueButton={handleIpqSaveContinueButton}
          handleIpqSaveExitButton={handleIpqSaveExitButton}
          loading={state.loading}
          newIpqMode={state.newIpqMode}
          onClose={handleClose}
          open={open}
          setKeyValue={state.setKeyValue}
          setShowDisclosuresModal={setShowDisclosuresModal}
          showError={state.showError}
          showWarning={state.showWarning}
          userData={state.managedUsers?.find(
            it => it.email === state.activeEmail
          )}
        />
      );
    else if (isOrganizationRivershares(organization))
      return () => (
        <QuestionnaireContentRivershares
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      );
    else if (isOrganizationAstor(organization))
      return () => (
        <QuestionnaireContentAstor
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      );
    else if (state.showQuestionnaireContentRTQ)
      return () => (
        <QuestionnaireContentRTQ
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      );
    else
      return () => (
        <QuestionnaireContentLevel
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      );
  }, [open]);

  return (
    <StyledModal closable={false} footer={false} open={open} width="100vw">
      <Content />

      <ModalDisclosures
        handleClose={() => setShowDisclosuresModal(false)}
        open={showDisclosuresModal}
      />
    </StyledModal>
  );
};

export default ModalProposalGuideManager;
