import styled from "styled-components";

const StylesContainer = styled.div`
  .highcharts-button-box {
    fill: transparent;
  }

  .highcharts-button-pressed {
    .highcharts-button-box {
      fill: #072c2b;
    }

    text {
      fill: #fff !important;
      font-weight: 500 !important;
    }
  }

  .highcharts-range-input text {
    fill: #1e1919 !important;
  }

  .highcharts-credits {
    display: none;
  }

  h3 {
    font-size: 19px;
    line-height: 140%;
    margin: 0 0 8px;
  }

  p {
    line-height: 140%;
    margin: 0;
  }
`;

export default StylesContainer;
