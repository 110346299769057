import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Row, Space, theme, Tooltip } from "antd";
import CardSkeleton from "../../../../atom/CardSkeleton";
import DashboardCompare from "../../../../organism/DashboardCompare";
import ModalConfirmSaveProposal from "../ModalConfirmSaveProposal";
import ModalGoalSettings from "../../../goals/components/ModalGoalSettings";
import ModalProductDetails from "../../../../organism/ModalProductDetails";
import ModalRelatedProducts from "../../../goals/components/ModalRelatedProducts";
import ProposalGoalDashboard from "../ProposalGoalDashboard";

import StylesContainer from "./style";

import {
  DEFAULT_ADVISOR_FEE,
  DEFAULT_BIRTH_YEAR,
  DEFAULT_RETIREMENT_AGE,
  DEFAULT_START_WITHDRAWAL_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../utils/constant";
import {
  DEFAULT_PROPOSAL_INITIALIZATION_DATA,
  PORTFOLIO_X_RAY_LIST,
} from "../../../oneascent/constant";
import { PROPOSAL_SAMPLE_URL_LEVEL } from "../../../level/constants";

import {
  downloadProposal,
  getLiveAssessment,
  updateProductObjective,
} from "../../../../utils/request/regularApp";
import {
  calculateWithdrawalLevelFromWithdrawalAmount,
  convertCurrentAgeRange,
  convertInvestmentDurationRange,
  getLastSavedProposalProps,
  getManuallyUpdatedOAGoalRecommendedProduct,
  isDemoProposalUser,
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
  isOrganizationLevel,
  isOrganizationOneascent,
} from "../../../../utils/helper/specialized";
import {
  cleanPercentValue,
  getPercentValue,
} from "../../../../utils/helper/general";
import { getRecommendedProductId } from "./helpers";

import { DownloadOutlined } from "@ant-design/icons";

const ViewProposalGoal = ({
  activeGoal,
  handleCloseGoal,
  handleGeneratePdf,
  productsList,
}) => {
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const [compareMode, setCompareMode] = useState(false);
  const [confirmSaveDialogOpened, setConfirmSaveDialogOpened] = useState(false);
  const [proposalData, setProposalData] = useState(null);
  const [proposalDirty, setProposalDirty] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);

  useEffect(() => {
    if (
      state.proposalViewMode === "pdf" &&
      proposalData &&
      state.userManagerData
    ) {
      // required for Astor & Rivershares pdf generators
      const interval = setInterval(() => {
        const barsChartSvg = localStorage.getItem("barsChartSvg");
        const growthChartSvg = localStorage.getItem("growthChartSvg");
        const pieChartSvg = localStorage.getItem("pieChartSvg");
        const pieChartWithLegendSvg = localStorage.getItem(
          "pieChartWithLegendSvg"
        );

        if (growthChartSvg && pieChartSvg && pieChartWithLegendSvg) {
          downloadProposal({
            barsChartSvg: barsChartSvg,
            guideInitialData: state.getPreferenceValue("guideInitialData"),
            growthChartSvg: growthChartSvg,
            insufficientFundsStartingIn: state.insufficientFundsStartingIn,
            pieChartSvg: pieChartSvg,
            pieChartWithLegendSvg: pieChartWithLegendSvg,
            proposalData: proposalData,
            userManagerData: state.userManagerData,
            userName: `${state.personalInfo.firstName} ${state.personalInfo.lastName}`,
          });

          state.setKeyValue("proposalViewMode", "goals");
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [proposalData, state.proposalViewMode]);

  useEffect(() => {
    if (!state.loading) {
      if (!proposalData) {
        const prospectObjective = state.getPreferenceValue("prospectObjective");
        const productMap = state.getPreferenceValue("productMap");

        if (activeGoal && productMap) {
          // proposal saved, use last saved active goal props
          const activeGoalLastSavedProps =
            productMap[activeGoal][productMap[activeGoal].length - 1];

          setProposalData({
            ...activeGoalLastSavedProps,
            birthYear: undefined,
            contributions:
              activeGoalLastSavedProps.contributions ??
              activeGoalLastSavedProps.savingsEachMonth * 12,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              activeGoalLastSavedProps.withdrawalLevel > 100
                ? Math.round(
                    (activeGoalLastSavedProps.withdrawalLevel /
                      activeGoalLastSavedProps.investmentAmount) *
                      100
                  )
                : activeGoalLastSavedProps.withdrawalLevel,
          });
        } else if (productMap) {
          // proposal saved, use last saved proposal props
          const lastSavedProposalProps = getLastSavedProposalProps(productMap);
          const prospectObjective =
            state.getPreferenceValue("prospectObjective");

          setProposalData({
            ...prospectObjective,
            birthYear: undefined,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            timestamp: undefined,
            withdrawalLevel:
              lastSavedProposalProps.withdrawalLevel > 100
                ? Math.round(
                    (lastSavedProposalProps.withdrawalLevel /
                      lastSavedProposalProps.investmentAmount) *
                      100
                  )
                : lastSavedProposalProps.withdrawalLevel,
          });
        } else if (prospectObjective) {
          // proposal do not saved, use quiz props
          const investmentDuration = isNaN(prospectObjective.investmentDuration)
            ? convertInvestmentDurationRange(
                prospectObjective.investmentDuration
              )
            : +(
                prospectObjective.investmentDuration ??
                DEFAULT_PROPOSAL_INITIALIZATION_DATA.investmentDuration
              );

          const currentAge =
            prospectObjective.currentAge ??
            convertCurrentAgeRange(prospectObjective.currentAgeRange);

          setProposalData({
            ...prospectObjective,
            advisorFee: prospectObjective.advisorFee ?? DEFAULT_ADVISOR_FEE,
            birthYear: currentAge
              ? undefined
              : prospectObjective.birthYear || DEFAULT_BIRTH_YEAR,
            currentAge,
            contributions:
              prospectObjective.contributions ??
              DEFAULT_PROPOSAL_INITIALIZATION_DATA.contributions,
            investmentAmount:
              prospectObjective.investmentAmount ??
              DEFAULT_PROPOSAL_INITIALIZATION_DATA.investmentAmount,
            householdIncome:
              prospectObjective.householdIncome ??
              DEFAULT_PROPOSAL_INITIALIZATION_DATA.householdIncome,
            incomeChartType: isGrowthTypeRiskTolerance(
              prospectObjective.investmentObjective
            )
              ? "contributions"
              : "income",
            investmentDuration,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              prospectObjective.withdrawalLevel > 100
                ? calculateWithdrawalLevelFromWithdrawalAmount({
                    contributions: prospectObjective.contributions,
                    fee: cleanPercentValue(prospectObjective.advisorFee),
                    investmentAmount: prospectObjective.investmentAmount,
                    riskTolerance: prospectObjective.riskTolerance,
                    withdrawalAmount: prospectObjective.withdrawalLevel,
                    yearToStartWithdrawal:
                      prospectObjective.yearToStartWithdrawals ??
                      Math.round(investmentDuration / 2),
                  })
                : (prospectObjective.withdrawalLevel ??
                  DEFAULT_WITHDRAWAL_LEVEL),
            yearToStartWithdrawals:
              prospectObjective.yearToStartWithdrawals ||
              Math.round(investmentDuration / 2) ||
              DEFAULT_START_WITHDRAWAL_YEAR,
          });
        } else if (isOrganizationOneascent(state.organization?.name)) {
          setProposalData(DEFAULT_PROPOSAL_INITIALIZATION_DATA);
        }
      }
    }
  }, [state]);

  useEffect(() => {
    if (proposalData) {
      const recommendedProductId =
        state.selectedProduct ??
        proposalData.recommendedProductId ??
        proposalData.productId ??
        getRecommendedProductId({
          organization: state.organization?.name ?? state.orgName,
          productsList,
          proposalData,
        });

      if (recommendedProductId) {
        setState(lastState => ({
          ...lastState,
          selectedProduct: recommendedProductId,
          compareProducts: [recommendedProductId],
        }));
      }
    }
  }, [productsList, proposalData, state.proposalViewMode]);

  useEffect(() => {
    if (!activeGoal) {
      setProposalDirty(true);
    }
  }, [activeGoal]);

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleProposalDataChange = useDebouncedCallback((values, skipDirty) => {
    if (!isLockedByAdminView(state.showWarning)) {
      !skipDirty && setProposalDirty(true);

      setProposalData(lastState => ({
        ...lastState,
        ...values,
      }));
    }
  }, 500);

  const handleSaveProposal = () => {
    state.setKeyValue("loading", true);

    if (state.getPreferenceValue("prospectObjective")) {
      // proposal quiz passed, just save
      saveProposal();
    } else {
      // proposal quiz skipped, set default proposal props
      updateProductObjective({
        ...DEFAULT_PROPOSAL_INITIALIZATION_DATA,
        email: state._id,
      }).then(() => saveProposal());
    }
  };

  const saveProposal = () =>
    getLiveAssessment({
      objective: {
        ...proposalData,
        investmentDuration:
          proposalData?.investmentDuration ?? proposalData?.lengthOfInvestment,
        productId: state.selectedProduct,
        timestamp: undefined,
        email: state._id,
      },
      organization: state.organization?.name?.toLowerCase(),
      productId: state.selectedProduct,
      saveAction: activeGoal ? "PUT" : "POST",
      saveId: activeGoal,
      activeGoal,
    })
      .then(() => {
        setProposalDirty(false);
        state.showSuccess("Proposal saved");
        setConfirmSaveDialogOpened(false);
        setTimeout(() => {
          state.setUserData().then(data => {
            if (activeGoal) {
              //update existed active goal
              setProposalData(
                data.preferences.valueMap.productMap[activeGoal][
                  data.preferences.valueMap.productMap[activeGoal].length - 1
                ]
              );
            } else {
              //set new created goal ID as active goal
              const createdGoalId = Object.keys(
                data.preferences.valueMap.productMap
              )[Object.keys(data.preferences.valueMap.productMap).length - 1];

              state.setKeyValue("activeGoal", createdGoalId);
              setProposalData(
                data.preferences.valueMap.productMap[createdGoalId][
                  data.preferences.valueMap.productMap[createdGoalId].length - 1
                ]
              );
            }
            state.setKeyValue("loading", false);
          });
        }, 1000);
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error);
      });

  const handleUpdateGoal = updatedProposalData => {
    setProposalData(updatedProposalData);
    state.closeModal("goalSettingsModal");
    state.showSuccess("Goal settings updated");
    setProposalDirty(true);

    const recommendedProduct = getManuallyUpdatedOAGoalRecommendedProduct({
      customRiskTolerance: updatedProposalData.riskTolerance,
      currentSelectedProduct: productsList.find(
        it => it._id === state.selectedProduct
      ),
      productsList,
    });

    if (recommendedProduct) {
      setState(lastState => ({
        ...lastState,
        compareProducts: [recommendedProduct._id],
        selectedProduct: recommendedProduct._id,
      }));
    }
  };

  const defaultListCardAction = [
    {
      buttonActiveCopy: "Added to Proposal",
      buttonCopy: "Add to Proposal",
      key: "addToProposal",
      onSelect: product => {
        state.setKeyValue("openModalRelatedProducts", false);
        const newProductData = productsList.find(it => it._id === product._id);

        if (newProductData) {
          handleProposalDataChange({
            ...proposalData,
            riskTolerance: newProductData.riskTolerance,
          });
        }

        setState(lastState => ({
          ...lastState,
          compareProducts: [product._id],
          selectedProduct: product._id,
        }));
      },
    },
  ];

  const compareListCardAction = [
    {
      buttonActiveCopy: "Selected to Compare",
      buttonCopy: "Add to Compare",
      key: "addToCompare",
      onSelect: product => {
        setState(lastState => ({
          ...lastState,
          compareProducts: lastState.compareProducts.includes(product._id)
            ? lastState.compareProducts.filter(id => id !== product._id)
            : [...lastState.compareProducts, product._id],
        }));

        setIsCompareChartDirty(true);
        state.setKeyValue("openModalRelatedProducts", false);
      },
    },
  ];

  const handleButtonSaveProposal = () => {
    if (isOrganizationOneascent(state.organization?.name)) {
      if (
        !state.getPreferenceValue("prospectObjective") &&
        state.userManagerData?.onboardingQuestionnaireType === "IPQ"
      ) {
        // IPQ onboarding quiz skipped, abort saving
        state.showWarning(
          "Before creating a proposal, you must complete an IPQ. On the dashboard page, choose either New Proposal or New IPQ to complete this."
        );
        return;
      }
    }

    setConfirmSaveDialogOpened(true);
  };

  const handleDownloadProposalClick = () => {
    if (isOrganizationOneascent(state.organization?.name)) {
      state.setKeyValue("activeGoal", activeGoal);
      handleGeneratePdf();
      return;
    }

    setState(lastState => ({
      ...lastState,
      activeGoal,
      productDetailsId: state.selectedProduct,
    }));

    localStorage.removeItem("barsChartSvg");
    localStorage.removeItem("growthChartSvg");
    localStorage.removeItem("pieChartSvg");
    localStorage.removeItem("pieChartWithLegendSvg");

    state.setKeyValue("proposalViewMode", "pdf");
    state.openModal("productDetailsModal");
  };

  return (
    <StylesContainer>
      <CardSkeleton loading={state.loading}>
        <Row
          justify="space-between"
          style={{
            paddingBottom: 15,
            borderBottom: `1.5px solid ${token.colorPrimary}`,
          }}
        >
          <Space direction="vertical" size={0}>
            <span style={{ color: token.color_grey_1 }}>Goal</span>
            <b
              style={{
                fontSize: 24,
                color: token.color_navy,
                textTransform: "capitalize",
              }}
            >
              {proposalData?.investmentObjective}
            </b>
          </Space>
          {compareMode ? (
            <Space align="end">
              <Button
                onClick={() => setCompareMode(false)}
                shape="round"
                style={{
                  background: token.tenant_color_primary,
                  borderColor: "transparent",
                  color: "#FFFFFF",
                  width: 178,
                }}
              >
                Exit Compare Mode
              </Button>
            </Space>
          ) : (
            !isDemoProposalUser(state._id) && (
              <Space align="end" size={22} wrap>
                {!proposalDirty && proposalData?.timestamp ? (
                  <>
                    <Space
                      direction="vertical"
                      size={0}
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ fontSize: 12, color: token.color_grey_1 }}>
                        Created
                      </span>
                      <span style={{ fontSize: 12, color: token.color_black }}>
                        {dayjs(proposalData.timestamp).format(
                          "MM/DD/YYYY h:mma"
                        )}
                      </span>
                    </Space>
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={handleDownloadProposalClick}
                      shape="round"
                      style={{
                        background: token.tenant_color_primary,
                        color: "#FFFFFF",
                        borderColor: "transparent",
                      }}
                    >
                      Download Proposal
                    </Button>
                    <Button
                      onClick={handleCloseGoal}
                      shape="round"
                      type="primary"
                    >
                      Close
                    </Button>
                  </>
                ) : (
                  <>
                    <Tooltip title="Please save the proposal before closing">
                      <Button
                        onClick={handleCloseGoal}
                        shape="round"
                        type="text"
                        disabled={true}
                      >
                        Close
                      </Button>
                    </Tooltip>
                    <Button
                      disabled={isLockedByAdminView({
                        managerAccess: state.managerAccess,
                      })}
                      onClick={handleButtonSaveProposal}
                      shape="round"
                      style={{
                        width: 156,
                        background: token.tenant_color_primary,
                        color: "#FFFFFF",
                        borderColor: "transparent",
                      }}
                    >
                      Save Proposal
                    </Button>
                  </>
                )}
              </Space>
            )
          )}
          {isDemoProposalUser(state._id) &&
            isOrganizationLevel(state.organization?.name ?? state.orgName) && (
              <Button
                icon={<DownloadOutlined />}
                onClick={() => window.open(PROPOSAL_SAMPLE_URL_LEVEL)}
                shape="round"
                style={{
                  background: token.tenant_color_primary,
                  color: "#FFFFFF",
                  borderColor: "transparent",
                }}
              >
                Download Proposal
              </Button>
            )}
        </Row>
        {compareMode ? (
          <DashboardCompare
            closeCompareMode={() => setCompareMode(false)}
            handleCardClick={handleCardClick}
            isCompareChartDirty={isCompareChartDirty}
            openProductsListModal={() =>
              state.setKeyValue("openModalRelatedProducts", true)
            }
            productsList={productsList}
            proposalData={proposalData}
            setIsCompareChartDirty={setIsCompareChartDirty}
            setState={setState}
            showError={state.showError}
            showSecondaryButton={true}
            state={state}
          />
        ) : (
          <ProposalGoalDashboard
            activeGoal={activeGoal}
            handleCardClick={handleCardClick}
            handleProposalDataChange={handleProposalDataChange}
            openCompareMode={() => setCompareMode(true)}
            productsList={productsList}
            proposalData={proposalData}
          />
        )}

        <ModalConfirmSaveProposal
          handleClose={() => setConfirmSaveDialogOpened(false)}
          handleSaveProposal={handleSaveProposal}
          isFirstSave={
            !(state.getPreferenceValue("productMap") ?? [])[activeGoal]
          }
          open={confirmSaveDialogOpened}
        />
        <ModalProductDetails
          handleClose={() => state.closeModal("productDetailsModal")}
          hideMcSettingsToggler={true}
          investmentAssumptions={{}}
          open={state.productDetailsModal}
          organization={state.organization?.name}
          product={{
            ...productsList.find(it => it._id === state.productDetailsId),
            ...proposalData,
            advisorFee: getPercentValue(proposalData?.advisorFee),
            investmentDuration:
              proposalData?.investmentDuration ??
              proposalData?.lengthOfInvestment,
            withdrawalLevel: getPercentValue(
              proposalData?.withdrawalLevel ?? 0
            ),
          }}
          productsList={productsList}
          xRayList={
            isOrganizationOneascent(state.organization?.name) &&
            PORTFOLIO_X_RAY_LIST
          }
        />
      </CardSkeleton>
      <ModalGoalSettings
        handleClose={() => state.closeModal("goalSettingsModal")}
        handleUpdateGoal={handleUpdateGoal}
        loading={state.loading}
        open={state.goalSettingsModal}
        organization={state.organization?.name}
        proposalData={proposalData}
      />
      <ModalRelatedProducts
        cardActions={
          compareMode ? compareListCardAction : defaultListCardAction
        }
        compareProducts={state.compareProducts}
        favoriteFinancialProducts={state.favoriteFinancialProducts}
        handleCardClick={handleCardClick}
        handleClose={() => state.setKeyValue("openModalRelatedProducts", false)}
        hideFavoriteProducts={isOrganizationOneascent(state.organization?.name)}
        open={state.openModalRelatedProducts}
        productsList={productsList}
        selectedProduct={state.selectedProduct}
      />
    </StylesContainer>
  );
};

export default ViewProposalGoal;
