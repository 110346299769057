import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../context";
import QuestionnaireContentIPQ from "../../../oneascent/component/QuestionnaireContentIPQ";
import QuestionnaireContentWealth from "../../../oneascent/component/QuestionnaireContentWealth";
import ModalDisclosures from "./components/ModalDisclosures";

import StyledModal from "./style";

import { DEFAULT_BIRTH_YEAR } from "../../../../utils/constant";

import { updateProductObjective } from "../../../../utils/request/regularApp";

const ModalProposalGuideUser = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [state] = useContext(State);
  const [showDisclosuresModal, setShowDisclosuresModal] = useState(false);

  const handleCreateProposal = proposalData => {
    state.setKeyValue("showOverlay", true);

    handleClose();

    updateProductObjective({
      email: state._id,
      ...proposalData,
      birthYear:
        state.managedUsers?.find(it => it.email === state._id)?.birthYear ||
        proposalData.birthYear ||
        DEFAULT_BIRTH_YEAR,
      secondQuizPartSkipped: false,
    })
      .then(() =>
        state.setUserData().then(() => {
          navigate("/oneascent-proposal/goals");

          setTimeout(() => {
            state.setKeyValue("activeGoal", undefined);
            state.setKeyValue("proposalViewMode", "goal");
            state.setKeyValue("showOverlay", false);
            state.showSuccess("New proposal created!");
          }, 1000);
        })
      )
      .catch(error => {
        state.showError(error);
        state.setKeyValue("showOverlay", false);
      });
  };

  const handleIpqSaveContinueButton = ({ proposalData, callback }) => {
    state.setKeyValue("loading", true);

    saveUserGuideData(proposalData).then(() =>
      state.setUserData().then(() => {
        state.showSuccess("Questionnaire data saved");
        callback && callback();
        state.setKeyValue("loading", false);
      })
    );
  };

  const handleIpqSaveExitButton = ({ proposalData, callback }) => {
    state.setKeyValue("loading", true);

    saveUserGuideData({
      ...proposalData,
      secondQuizPartSkipped: true,
    }).then(() =>
      state.setUserData().then(() => {
        state.showSuccess("Questionnaire data saved");
        callback && callback();
        handleClose();
        state.setKeyValue("loading", false);
      })
    );
  };

  const saveUserGuideData = customProposalData =>
    updateProductObjective({
      email: state._id,
      ...(state.preferences?.valueMap?.prospectObjective ?? {}),
      ...customProposalData,
    }).catch(error => {
      state.showError(error);
      state.setKeyValue("loading", false);
    });

  return (
    <StyledModal open={open} width="100vw" footer={false} closable={false}>
      {state.userManagerData?.onboardingQuestionnaireType ===
        "wealthManagement" && (
        <QuestionnaireContentWealth
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
          setShowDisclosuresModal={setShowDisclosuresModal}
        />
      )}
      {state.userManagerData?.onboardingQuestionnaireType === "IPQ" && (
        <QuestionnaireContentIPQ
          handleCreateProposal={handleCreateProposal}
          handleIpqSaveContinueButton={handleIpqSaveContinueButton}
          handleIpqSaveExitButton={handleIpqSaveExitButton}
          loading={state.loading}
          newIpqMode={state.newIpqMode}
          onClose={handleClose}
          open={open}
          setKeyValue={state.setKeyValue}
          setShowDisclosuresModal={setShowDisclosuresModal}
          showError={state.showError}
          showWarning={state.showWarning}
          userData={state}
        />
      )}

      <ModalDisclosures
        handleClose={() => setShowDisclosuresModal(false)}
        open={showDisclosuresModal}
      />
    </StyledModal>
  );
};

export default ModalProposalGuideUser;
