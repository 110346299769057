import { useEffect, useState } from "react";
import axios from "axios";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import StylesContainer from "./style";

import { HISTORICAL_CHART_TYPE_DEFAULT } from "../../../../constants";

const SectionHistorical = ({ product }) => {
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!product) {
      setHistoricalData(null);
      return;
    }

    setLoading(true);

    axios
      .get(`/assets/product_historical_data/${product.name}.json`)
      .then(({ data }) => {
        setHistoricalData(data?.data);
        // better UX to prevent flashing
        setTimeout(() => setLoading(false), 1000);
      })
      .catch(() => {
        // selected product historical data do not hosted
        // fetch default product historical data
        axios
          .get(
            `/assets/product_historical_data/${HISTORICAL_CHART_TYPE_DEFAULT}.json`
          )
          .then(({ data }) => {
            setHistoricalData(data?.data);
            // better UX to prevent flashing
            setTimeout(() => setLoading(false), 1000);
          })
          .catch(error => {
            console.log(error);
            setHistoricalData(null);
            setLoading(false);
          });
      });
  }, [product]);

  const options = {
    series: [
      {
        credits: {
          enabled: false,
        },
        data: historicalData?.map(it => [
          new Date(it.Date).getTime(),
          it.Close,
        ]),
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, HighStock.getOptions().colors[0]],
            [
              1,
              HighStock.color(HighStock.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        name: "SPY",
        threshold: null,
        type: "area",
      },
    ],
  };

  return (
    <StylesContainer>
      <ChartSkeleton loading={loading}>
        <HighchartsReact
          options={options}
          highcharts={HighStock}
          constructorType="stockChart"
        />
        <h3>About</h3>
        <p dangerouslySetInnerHTML={{ __html: product?.objective }} />
      </ChartSkeleton>
    </StylesContainer>
  );
};

export default SectionHistorical;
