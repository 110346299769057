import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../../context";
import { Breadcrumb, Button, Flex, Form, Progress, Radio, theme } from "antd";
import Bullet from "../../../../atom/Bullet";

import StylesContainer from "./style";

import { PROPOSAL_DEFAULT_DATA_LEVEL } from "../../constants";
import { LIST_STEP_DATA } from "./constants";

import { RightOutlined } from "@ant-design/icons";

const QuestionnaireContentRTQ = ({
  handleClose,
  handleCreateProposal,
  open,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  const activeUserData = state.managedUsers.find(
    it => it.email === state.activeEmail
  );

  const { title, description } = LIST_STEP_DATA[currentStepIndex];

  useEffect(() => {
    if (open) {
      setCurrentStepIndex(0);
      form.setFieldsValue(PROPOSAL_DEFAULT_DATA_LEVEL);
    }
  }, [open]);

  const handleSubmit = () => {
    const values = form.getFieldsValue();

    console.log("Debugging RTQ quiz => ", values);

    const experienceScore = Object.keys(values).reduce(
      (acc, key) => (key.includes("experience__") ? acc + values[key] : acc),
      0
    );

    const timelineScore = Object.keys(values).reduce(
      (acc, key) => (key.includes("timeline__") ? acc + values[key] : acc),
      0
    );

    handleCreateProposal({
      ...PROPOSAL_DEFAULT_DATA_LEVEL,
      riskTolerance: calculateRiskTolerance({
        experienceScore,
        timelineScore,
      }),
      passedRTQ: true,
    });
    setCurrentStepIndex(0);
  };

  const calculateRiskTolerance = ({ experienceScore, timelineScore }) => {
    if (experienceScore <= 3) {
      if (timelineScore <= 33) return "Preservation";
      else return "Conservative";
    } else if (experienceScore <= 6) {
      if (timelineScore <= 12) return "Preservation";
      else return "Conservative";
    } else if (experienceScore <= 9) {
      if (timelineScore < -9) return "Preservation";
      else if (timelineScore <= 15) return "Conservative";
      else return "Moderate";
    } else if (experienceScore <= 12) {
      if (timelineScore <= 9) return "Conservative";
      else if (timelineScore <= 24) return "Moderate";
      else return "Growth";
    } else if (experienceScore <= 15) {
      if (timelineScore <= 18) return "Moderate";
      else if (timelineScore <= 30) return "Growth";
      else return "Aggressive";
    } else if (experienceScore <= 18) {
      if (timelineScore <= 9) return "Moderate";
      else if (timelineScore <= 24) return "Growth";
      else return "Aggressive";
    } else {
      if (timelineScore <= 15) return "Growth";
      else return "Aggressive";
    }
  };

  return (
    <StylesContainer align="center" token={token} vertical>
      <header>
        <Flex align="center" className="copy-container" justify="space-between">
          <h4>
            Proposal:{" "}
            <b>
              {activeUserData?.firstName} {activeUserData?.lastName}
            </b>
          </h4>
          <Button onClick={handleClose} shape="round">
            Close
          </Button>
        </Flex>
        <Flex align="center" justify="end" className="breadcrumb-container">
          <Breadcrumb
            items={[
              {
                className: "active",
                title: <p>Start Planning</p>,
              },
              {
                title: <p>Select Product</p>,
              },
              {
                title: <p>Review Proposal</p>,
              },
              {
                title: (
                  <Button
                    disabled={currentStepIndex !== 2}
                    onClick={handleSubmit}
                    shape="round"
                  >
                    Save Proposal
                  </Button>
                ),
              },
            ]}
            separator={<RightOutlined />}
          />
        </Flex>
        <Progress
          percent={(100 / LIST_STEP_DATA.length) * (currentStepIndex + 1)}
          showInfo={false}
          strokeColor={{ from: "#E2E86C", to: "#4F8083" }}
          strokeLinecap="square"
          trailColor="#A9C8CA"
        />
      </header>

      <main>
        <div className="description-container">
          <h2 className="title">{title}</h2>
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <div className="form-container">
          <Form id="levelQuestionnaireForm" form={form} layout="vertical">
            {LIST_STEP_DATA.map((it, index) => (
              <Form.Item
                hidden={currentStepIndex !== index}
                name={it.formItemName}
                key={it.formItemName}
              >
                <Radio.Group>
                  <Flex align="center" gap={24} vertical>
                    {it.options?.map(({ Icon, label, value }) => (
                      <Radio.Button
                        className={!Icon && "no-icon"}
                        key={label}
                        value={value}
                      >
                        <Flex align="center" gap={24}>
                          {Icon}
                          {label}
                        </Flex>
                      </Radio.Button>
                    ))}
                  </Flex>
                </Radio.Group>
              </Form.Item>
            ))}
          </Form>
        </div>
      </main>

      <footer>
        {currentStepIndex === 0 ? (
          <Button
            block={isSmallScreen}
            onClick={handleClose}
            shape="round"
            style={{ order: isSmallScreen ? 3 : 1 }}
            type="text"
          >
            Cancel
          </Button>
        ) : (
          <Button
            block={isSmallScreen}
            onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
            shape="round"
            style={{ order: isSmallScreen ? 3 : 1 }}
            type="text"
          >
            Previous
          </Button>
        )}

        <Flex gap={12} justify="center" style={{ order: 2 }}>
          {Array(LIST_STEP_DATA.length)
            .fill(null)
            .map((_, index) => (
              <Bullet
                color={currentStepIndex === index ? "#4F8083" : "#749B9E50"}
                key={index}
                size={12}
              />
            ))}
        </Flex>

        {currentStepIndex === LIST_STEP_DATA.length - 1 ? (
          <Button
            block={isSmallScreen}
            disabled={state.loading}
            onClick={handleSubmit}
            shape="round"
            style={{ order: isSmallScreen ? 1 : 3 }}
            type="primary"
          >
            Let’s Go
          </Button>
        ) : (
          <Button
            block={isSmallScreen}
            onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
            shape="round"
            style={{ order: isSmallScreen ? 1 : 3, width: 126 }}
            type="primary"
          >
            Continue
          </Button>
        )}
      </footer>
    </StylesContainer>
  );
};

export default QuestionnaireContentRTQ;
