import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Flex, Input, Table } from "antd";
import AnalyticValue from "../../../../atom/AnalyticValue";
import ModalViewIpq from "../../../oneascent/component/ModalViewIpq";

import StylesContainer from "./style";

import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../utils/helper/general";
import { openProposal } from "../../../../utils/request/manager";
import {
  getAllManagedClientsOfAdvisors,
  isOrganizationOneascent,
} from "../../../../utils/helper/specialized";

const PageAdminClients = () => {
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const getClientAssets = value => (isNaN(value) ? 0 : +value);

  const getTableData = () =>
    getAllManagedClientsOfAdvisors(state.managedUserManagers).map(it => ({
      ...it,
      assets: getClientAssets(
        isOrganizationOneascent(state.organization?.name)
          ? it.proposedAmount
          : it.user?.preferences?.valueMap?.guideInitialData?.assets
      ),
      clientName: it.lastName + ", " + it.firstName,
      firmName: it.firmName ?? it.brokerDealerName,
      key: it.email,
    }));

  const handleActionViewActiveProposal = userEmail => {
    openProposal({
      organization: state.organization?.name,
      userEmail,
      windowName: "adminView",
    });
  };

  const handleActionViewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalViewIpq", true);
  };

  return (
    <StylesContainer>
      <header>Clients</header>
      <Flex
        gap={30}
        style={{
          marginBottom: 16,
        }}
      >
        <Input
          style={{
            maxWidth: 228,
            height: 40,
            borderRadius: 25,
          }}
          placeholder="Search..."
          onChange={event => setSearchedText(event.target.value)}
        />
        <AnalyticValue label="Total Clients" value={getTableData().length} />
        <AnalyticValue
          label="Total Proposed AUM"
          value={getCurrencyValueWithRoundSign(
            getTableData().reduce((acc, it) => acc + it.assets, 0)
          )}
        />
      </Flex>
      <Table
        dataSource={getTableData()}
        pagination={false}
        loading={state.loading}
        scroll={{
          x: 1450,
        }}
      >
        <Table.Column
          dataIndex="clientName"
          filteredValue={[searchedText]}
          key="clientName"
          onFilter={(value, record) =>
            String(record.clientName)
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            String(record.email).toLowerCase().includes(value.toLowerCase())
          }
          render={(clientName, restValues) => (
            <a
              onClick={() =>
                openProposal({
                  userEmail: restValues.email,
                  organization: state.organization?.name,
                })
              }
            >
              {clientName}
            </a>
          )}
          sorter={(a, b) => a.clientName.localeCompare(b.clientName)}
          title="Client Name"
        />
        <Table.Column
          dataIndex="email"
          key="email"
          render={value => <a href={`mailto:${value}`}>{value}</a>}
          sorter={(a, b) => a.email.localeCompare(b.email)}
          title="Email"
        />
        <Table.Column
          dataIndex="advisorEmail"
          key="advisorEmail"
          sorter={(a, b) => a.advisorEmail.localeCompare(b.advisorEmail)}
          title="Advisor"
        />
        <Table.Column
          dataIndex="firmName"
          key="firmName"
          sorter={(a, b) => a.firmName.localeCompare(b.firmName)}
          title="Firm name"
        />
        <Table.Column
          align="left"
          key="ipq"
          render={(_, allValues) =>
            allValues.onboardingQuestionnaireType === "IPQ" &&
            allValues.valueMap?.prospectObjective && (
              <a onClick={() => handleActionViewIpq(allValues.email)}>
                <u>View</u>
              </a>
            )
          }
          title="IPQ"
        />
        <Table.Column
          align="right"
          key="activeProposal"
          render={(_, allValues) =>
            allValues.questionnaireAnswered && (
              <a
                onClick={() => handleActionViewActiveProposal(allValues.email)}
              >
                <u>View</u>
              </a>
            )
          }
          title="Active Proposal"
        />
        <Table.Column
          align="right"
          dataIndex="assets"
          key="assets"
          render={value => getCurrencyValue(value)}
          sorter={(a, b) => a.assets.localeCompare(b.assets)}
          title="Assets"
        />
        <Table.Column
          align="right"
          dataIndex="lastLoginTime"
          key="lastLoginTime"
          sorter={(a, b) => dayjs(a.lastLoginTime) - dayjs(b.lastLoginTime)}
          title="Last Accessed"
        />
      </Table>

      <ModalViewIpq
        activeUserData={getAllManagedClientsOfAdvisors(
          state.managedUserManagers
        )?.find(it => it.email === state.activeEmail)}
        onCancel={() => state.setKeyValue("openModalViewIpq", false)}
        open={state.openModalViewIpq}
      />
    </StylesContainer>
  );
};

export default PageAdminClients;
