import { useContext, useMemo } from "react";
import State from "../../../../../../context";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { Flex, Statistic } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StylesContainer from "./style";

import {
  getAllManagedUsersOfAdvisors,
  getLastSavedProposalProps,
  getManagedUsersTotalProposedAmount,
} from "../../../../../../utils/helper/specialized";
import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helper/general";

import {
  faArrowDownLong,
  faArrowUpLong,
} from "@fortawesome/free-solid-svg-icons";

dayjs.extend(quarterOfYear);

const StatisticContainer = ({ descriptionCopy, gap, suffix, title, value }) => (
  <Flex vertical>
    <Statistic title={title} value={value} suffix={suffix} />
    {descriptionCopy ? (
      <div className="description">{descriptionCopy}</div>
    ) : (
      <div className="description">
        {gap > 0 ? (
          <h5 className="up">
            {Math.round(gap * 100) / 100}%
            <FontAwesomeIcon icon={faArrowUpLong} />
          </h5>
        ) : gap < 0 ? (
          <h5 className="down">
            {Math.round(gap * 100) / 100}%
            <FontAwesomeIcon icon={faArrowDownLong} />
          </h5>
        ) : (
          <h5>0%</h5>
        )}
        <span>than last quarter</span>
      </div>
    )}
  </Flex>
);

const SectionAdvisorsAnalytic = () => {
  const [state] = useContext(State);

  const currentYear = +dayjs().format("YYYY");
  const currentQuarter = dayjs().quarter();

  // if last quarter value is zero, need to remove Infinity value from the calculation
  const validateGap = gap => (gap === Infinity ? 100 : gap);

  const calculation = useMemo(() => {
    if (!state.managedUserManagers) return {};

    const advisorsList = state.managedUserManagers;

    const currentQuarterAdvisors = advisorsList.filter(it => {
      const creationYear = +dayjs(it.userManager.createDateTime.$date).format(
        "YYYY"
      );

      const creationQuarter = dayjs(
        it.userManager.createDateTime.$date
      ).quarter();

      return creationYear === currentYear && creationQuarter === currentQuarter;
    });

    const advisorsGap =
      (currentQuarterAdvisors?.length /
        (advisorsList?.length - currentQuarterAdvisors?.length)) *
      100;

    const dateBeforeTodayOn30Days = dayjs().subtract(30, "day");
    const dateBeforeTodayOn31Days = dayjs().subtract(31, "day");
    const dateBeforeTodayOn60Days = dayjs().subtract(60, "day");

    const activeAdvisors30Days = advisorsList.filter(
      it =>
        it.userManager?.lastLoginTime?.$date &&
        dayjs(it.userManager.lastLoginTime.$date).isAfter(
          dateBeforeTodayOn30Days
        )
    );

    const activeAdvisors31To60Days = advisorsList.filter(
      it =>
        it.userManager?.lastLoginTime?.$date &&
        dayjs(it.userManager.lastLoginTime.$date).isAfter(
          dateBeforeTodayOn60Days
        ) &&
        dayjs(it.userManager.lastLoginTime.$date).isBefore(
          dateBeforeTodayOn31Days
        )
    );

    const activeAdvisorsGap =
      ((activeAdvisors30Days.length - activeAdvisors31To60Days.length) /
        activeAdvisors31To60Days.length) *
      100;

    const managedUsers = getAllManagedUsersOfAdvisors(
      state.managedUserManagers
    );

    const clientsList = managedUsers
      .filter(it => !it.isDeleted)
      .filter(
        elem =>
          elem.userType !== "Prospect" &&
          !(
            elem.entryPoint === "addedByUserManager" &&
            elem.userType === "Assigned"
          )
      );

    const currentQuarterClients = clientsList.filter(it => {
      if (!it.user?.createDateTime?.$date) return false;

      const creationYear = +dayjs(it.user.createDateTime.$date).format("YYYY");
      const creationQuarter = dayjs(it.user.createDateTime.$date).quarter();

      return creationYear === currentYear && creationQuarter === currentQuarter;
    });

    const clientsGap =
      (currentQuarterClients?.length /
        (clientsList?.length - currentQuarterClients?.length)) *
      100;

    const prospectsList = managedUsers
      .filter(it => !it.isDeleted)
      .filter(
        elem =>
          elem.userType === "Prospect" ||
          (elem.entryPoint === "addedByUserManager" &&
            elem.userType === "Assigned")
      );

    const currentQuarterProspects = prospectsList.filter(it => {
      if (!it.user?.createDateTime?.$date) return false;

      const creationYear = +dayjs(it.user.createDateTime.$date).format("YYYY");
      const creationQuarter = dayjs(it.user.createDateTime.$date).quarter();

      return creationYear === currentYear && creationQuarter === currentQuarter;
    });

    const prospectsGap =
      (currentQuarterProspects?.length /
        (prospectsList?.length - currentQuarterProspects?.length)) *
      100;

    const currentQuarterProposedAum = getManagedUsersTotalProposedAmount([
      ...currentQuarterClients,
      ...currentQuarterProspects,
    ]);

    const currentQuarterProposedAumGap =
      (currentQuarterProposedAum /
        (getManagedUsersTotalProposedAmount(managedUsers) -
          currentQuarterProposedAum)) *
      100;

    const latestProposalsList = managedUsers.filter(it => {
      const proposalSavedDate =
        it.proposalSavedDate?.$date ??
        (it.valueMap?.productMap &&
          getLastSavedProposalProps(it.valueMap.productMap)?.timestamp);

      if (!proposalSavedDate) return false;

      return dayjs(proposalSavedDate).isAfter(dayjs().subtract(134, "day"));
    });

    return {
      activeAdvisors: activeAdvisors30Days,
      activeAdvisorsGap: validateGap(activeAdvisorsGap),
      advisorsGap: validateGap(advisorsGap),
      advisorsList,
      clientsGap: validateGap(clientsGap),
      clientsList,
      latestProposalsList,
      prospectsGap: validateGap(prospectsGap),
      prospectsList,
      totalProposedAum: currentQuarterProposedAum,
      totalProposedAumGap: currentQuarterProposedAumGap,
    };
  }, [state.managedUserManagers]);

  console.log("Debugging calculation => ", calculation);

  return (
    <StylesContainer>
      <StatisticContainer
        gap={calculation.advisorsGap}
        title="Total Advisors"
        value={calculation.advisorsList?.length}
      />
      <StatisticContainer
        gap={calculation.clientsGap}
        title="Total Clients"
        value={calculation.clientsList?.length}
      />
      <StatisticContainer
        gap={calculation.prospectsGap}
        title="Total Prospects"
        value={calculation.prospectsList?.length}
      />
      <StatisticContainer
        gap={calculation.activeAdvisorsGap}
        title="Active Advisor Users"
        value={calculation.activeAdvisors?.length}
      />
      <StatisticContainer
        gap={calculation.totalProposedAumGap}
        title="Total Proposed AUM"
        value={getCurrencyValueWithRoundSign(calculation.totalProposedAum)}
      />
      <StatisticContainer
        descriptionCopy="In the past 7 days"
        title="Latest Proposals"
        value={calculation.latestProposalsList?.length}
      />
    </StylesContainer>
  );
};

export default SectionAdvisorsAnalytic;
