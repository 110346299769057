import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Flex } from "antd";

import StylesContainer from "./style.js";

import { addThousandCommaSeparator } from "../../../../../halo/utils";
import { getPercentValue } from "../../../../../../utils/helper/general";

const SectionRisk = () => {
  return (
    <StylesContainer>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            borderRadius: 12,
            spacingBottom: 50,
            height: 290,
          },
          credits: {
            enabled: false,
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            column: {
              stacking: "normal",
              borderRadius: 4,
            },
            series: {
              lineWidth: 5,
              marker: {
                enabled: false,
              },
            },
          },
          series: [
            {
              color: "#3AB0FA",
              data: [
                [70, 0],
                [80, 8],
                [90, 30],
                [100, 40],
                [110, 30],
                [120, 8],
                [130, 0],
              ],
              name: "Original Allocation",
              type: "spline",
              zIndex: 1,
            },
            {
              color: "#4F8083",
              data: [
                [70, 20],
                [80, 30],
                [90, 45],
                [100, 38],
                [110, 25],
                [120, 15],
                [130, 8],
              ],
              name: "Original Allocation",
              type: "column",
            },
          ],
          title: {
            text: null,
          },
          tooltip: {
            formatter: function () {
              return addThousandCommaSeparator(Math.round(this.y));
            },
            outside: true,
          },
          xAxis: {
            labels: {
              formatter: function () {
                return `${this.value}%`;
              },
              style: {
                color: "rgba(0, 0, 0, 0.83)",
                fontFamily: "Open Sans, sans-serif",
                fontSize: 14,
              },
            },
            lineColor: "transparent",
            lineWidth: 0,
            minorGridLineWidth: 0,
            tickLength: 0,
          },
          yAxis: {
            labels: {
              style: {
                color: "rgba(0, 0, 0, 0.83)",
                fontFamily: "Open Sans, sans-serif",
                fontSize: 14,
              },
            },
            title: {
              text: null,
            },
          },
        }}
      />
      <h4>Top 3 Drawdowns</h4>
      <Flex gap={16}>
        <div className="value-container">
          <span>[Time Period One]</span>
          <p>{getPercentValue(-45.9)}</p>
        </div>
        <div className="value-container">
          <span>[Time Period Two]</span>
          <p>{getPercentValue(-49.1)}</p>
        </div>
        <div className="value-container">
          <span>[Time Period Three]</span>
          <p>{getPercentValue(-34)}</p>
        </div>
      </Flex>
    </StylesContainer>
  );
};

export default SectionRisk;
