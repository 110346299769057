import { useContext, useEffect, useState } from "react";
import State from "../../../../context";
import { Button, Flex, Row, Skeleton, theme } from "antd";
import AstorProductsDashboard from "../../../astor/components/AstorProductsDashboard";
import DashboardCompare from "../../../../organism/DashboardCompare";
import DashboardLevelProducts from "../../../level/components/DashboardLevelProducts";
import DefaultProductAssumptionsModal from "./components/DefaultProductAssumptionsModal";
import ModalLevelProductDetails from "../../../level/components/ModalLevelProductDetails";
import ModalProductDetails from "../../../../organism/ModalProductDetails";
import ModalRelatedProducts from "../../../goals/components/ModalRelatedProducts";
import OneascentProductsDashboard from "../../../oneascent/component/OneascentProductsDashboard";
import RiversharesProductsDashboard from "../../../rivershares/components/RiversharesProductsDashboard";
import SecurityGate from "../../../../provider/SecurityGate";
import Top3ProductsBars from "./components/Top3ProductsBars";

import { ActionSubtitle, ActionTitle, StyledCollapse } from "./style";

import { MAX_COMPARE_PRODUCTS } from "../../../../utils/constant";
import { PORTFOLIO_X_RAY_LIST } from "../../../oneascent/constant";

import {
  addToFavoriteFinancialProducts,
  removeFromFavoriteFinancialProducts,
} from "../../../../utils/request/manager";
import {
  isOrganizationAstor,
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRivershares,
  isPortalAdmin,
} from "../../../../utils/helper/specialized";

import { DownOutlined, FilePdfOutlined, UpOutlined } from "@ant-design/icons";

const PageFinancialProducts = () => {
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const [compareMode, setCompareMode] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const organization = state.organization?.name;

  useEffect(() => {
    setFavoriteProducts(state.favoriteFinancialProducts);
  }, []);

  const handleViewDetails = product => {
    state.setKeyValue("productDetailsId", product._id);
    state.openModal("productDetailsModal");
  };

  const handleAddToFavorite = productId => {
    state.setKeyValue("loading", true);

    addToFavoriteFinancialProducts(productId)
      .then(() =>
        state.setAdminData().then(data => {
          setFavoriteProducts(data.favoriteFinancialProducts);
          state.showSuccess("Product marked as Favorite");
        })
      )
      .catch(console.log);
  };

  const handleRemoveFromFavorite = productId => {
    state.setKeyValue("loading", true);

    removeFromFavoriteFinancialProducts(productId)
      .then(() =>
        state.setAdminData().then(data => {
          setFavoriteProducts(data.favoriteFinancialProducts);
          state.showSuccess("Product removed from Favorite");
        })
      )
      .catch(console.log);
  };

  const productActions = product =>
    [
      {
        label: (
          <Flex gap={4} vertical>
            <ActionTitle role="action-item">
              {favoriteProducts?.find(it => it === product._id) ? (
                <span style={{ color: "red" }}>Remove from Favorite</span>
              ) : (
                "Mark as Favorite"
              )}
            </ActionTitle>
            <ActionSubtitle role="action-item">
              Favorites are shown to clients
            </ActionSubtitle>
          </Flex>
        ),
        key: "action-item-0",
        onSelect: product => {
          favoriteProducts?.find(it => it === product._id)
            ? handleRemoveFromFavorite(product._id)
            : handleAddToFavorite(product._id);
        },
        status:
          isOrganizationOneascent(organization) &&
          state.subscriptionType === "ManagedUserManager"
            ? "visible"
            : "hidden",
      },
      {
        label: (
          <Flex gap={4} vertical>
            <ActionTitle role="action-item">View Details</ActionTitle>
            <ActionSubtitle role="action-item">
              Read more about this product
            </ActionSubtitle>
          </Flex>
        ),
        key: "action-item-1",
        onSelect: product => {
          handleViewDetails(product);
        },
        status: "visible",
      },
      {
        label: (
          <Flex gap={4} vertical>
            <ActionTitle role="action-item">
              {state?.compareProducts?.includes(product?._id) ? (
                <span style={{ color: "red" }}>Remove from Compare</span>
              ) : (
                "Compare Strategies"
              )}
            </ActionTitle>
            <ActionSubtitle role="action-item">
              Choose up to 3 products
            </ActionSubtitle>
          </Flex>
        ),
        key: "action-item-2",
        onSelect: product => {
          if (
            state.compareProducts.length >= MAX_COMPARE_PRODUCTS &&
            !state.compareProducts.includes(product._id)
          ) {
            state.showWarning(
              `No more than ${MAX_COMPARE_PRODUCTS} products can be compared to at the same time`
            );

            return;
          }

          const productIncluded = state.compareProducts?.find(
            productId => productId === product._id
          );

          if (!productIncluded) {
            state.setKeyValue("compareProducts", [
              ...state.compareProducts,
              product._id,
            ]);
          } else {
            state.setKeyValue(
              "compareProducts",
              state.compareProducts.filter(
                productId => productId !== product._id
              )
            );
          }
          state.closeModal("productDetailsModal");
        },
        status: "visible",
      },
      {
        label: (
          <Flex gap={12}>
            <Flex gap={4} vertical>
              <ActionTitle role="action-item">Proposal Template</ActionTitle>
              <ActionSubtitle role="action-item">
                What the client sees
              </ActionSubtitle>
            </Flex>
            <FilePdfOutlined style={{ fontSize: 24 }} />
          </Flex>
        ),
        key: "action-item-3",
        onSelect: product => window.open(product.proposalPdfSample, "_blank"),
        status: isPortalAdmin() && "visible",
      },
    ].filter(it => it.status === "visible");

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const compareListCardAction = [
    {
      key: "addToCompare",
      buttonCopy: "Add to Compare",
      buttonActiveCopy: "Selected to Compare",
      onSelect: product => {
        setState(lastState => ({
          ...lastState,
          compareProducts: lastState.compareProducts.includes(product._id)
            ? lastState.compareProducts.filter(id => id !== product._id)
            : [...lastState.compareProducts, product._id],
        }));

        setIsCompareChartDirty(true);
        state.setKeyValue("openModalRelatedProducts", false);
      },
    },
  ];

  return (
    <SecurityGate state={state}>
      <Skeleton active loading={state.loading}>
        {compareMode ? (
          <div style={{ padding: token.manager_page_body_padding }}>
            <Row justify="end">
              <Button
                shape="round"
                style={{
                  background: token.tenant_color_primary,
                  borderColor: "transparent",
                  color: "#FFFFFF",
                  marginBottom: 16,
                  width: 178,
                }}
                onClick={() => setCompareMode(false)}
              >
                Exit Compare Mode
              </Button>
            </Row>
            <DashboardCompare
              closeCompareMode={() => setCompareMode(false)}
              handleCardClick={handleCardClick}
              isCompareChartDirty={isCompareChartDirty}
              openProductsListModal={() =>
                state.setKeyValue("openModalRelatedProducts", true)
              }
              productsList={state.productsList}
              proposalData={state.investmentAssumptions}
              setIsCompareChartDirty={setIsCompareChartDirty}
              setState={setState}
              showError={state.showError}
              showLegend={!isOrganizationRivershares(organization)}
              showSecondaryButton={false}
              state={state}
            />
          </div>
        ) : (
          <>
            {(isOrganizationOneascent(organization) ||
              isOrganizationRivershares(organization)) && (
              <StyledCollapse
                bordered={false}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <UpOutlined style={{ color: token.color_navy }} />
                  ) : (
                    <DownOutlined style={{ color: token.color_navy }} />
                  )
                }
                style={{ padding: 0, background: "none" }}
                items={[
                  {
                    key: "productStats",
                    label: "Product Stats",
                    children: (
                      <Top3ProductsBars productsList={state.productsList} />
                    ),
                  },
                ]}
              />
            )}
            {isOrganizationOneascent(organization) ? (
              <OneascentProductsDashboard
                comparedProducts={state.compareProducts}
                handleCardClick={handleCardClick}
                openModal={state.openModal}
                productActions={productActions}
                productsList={state.productsList}
                setCompareMode={setCompareMode}
              />
            ) : isOrganizationRivershares(organization) ? (
              <RiversharesProductsDashboard
                comparedProducts={state.compareProducts}
                handleCardClick={handleCardClick}
                openModal={state.openModal}
                productActions={productActions}
                productsList={state.productsList}
                setCompareMode={setCompareMode}
              />
            ) : isOrganizationAstor(organization) ? (
              <AstorProductsDashboard
                comparedProducts={state.compareProducts}
                handleCardClick={handleCardClick}
                productActions={productActions}
                productsList={state.productsList}
                setCompareMode={setCompareMode}
              />
            ) : (
              <DashboardLevelProducts handleCardClick={handleCardClick} />
            )}
          </>
        )}

        {isOrganizationLevel(organization) ? (
          <ModalLevelProductDetails
            handleClose={() => state.closeModal("productDetailsModal")}
            investmentAssumptions={state.investmentAssumptions}
            open={state.productDetailsModal}
            product={state.productsList?.find(
              it => it._id === state.productDetailsId
            )}
            showError={state.showError}
          />
        ) : (
          <ModalProductDetails
            open={state.productDetailsModal}
            handleClose={() => state.closeModal("productDetailsModal")}
            product={state.productsList?.find(
              it => it._id === state.productDetailsId
            )}
            productsList={state.productsList}
            investmentAssumptions={state.investmentAssumptions}
            organization={organization?.toLowerCase()}
            hideMcSettingsToggler={isOrganizationOneascent(organization)}
            xRayList={
              isOrganizationOneascent(organization) && PORTFOLIO_X_RAY_LIST
            }
          />
        )}
        <ModalRelatedProducts
          cardActions={compareListCardAction}
          compareProducts={state.compareProducts}
          favoriteFinancialProducts={state.favoriteFinancialProducts}
          handleCardClick={handleCardClick}
          handleClose={() =>
            state.setKeyValue("openModalRelatedProducts", false)
          }
          hideFavoriteProducts={isOrganizationOneascent(
            state.organizations?.name
          )}
          open={state.openModalRelatedProducts}
          productsList={state.productsList}
          selectedProduct={state.selectedProduct}
        />
        <DefaultProductAssumptionsModal
          handleClose={() => state.closeModal("defaultProductAssumptionsModal")}
          investmentAssumptions={state.investmentAssumptions}
          open={state.defaultProductAssumptionsModal}
        />
      </Skeleton>
    </SecurityGate>
  );
};

export default PageFinancialProducts;
