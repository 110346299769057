import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { Button, Flex, Image } from "antd";
import ReportPageIpq1 from "../../pdf/page/ReportPageIpq1";
import ReportPageIpq2 from "../../pdf/page/ReportPageIpq2";

import StyledModal from "./style";

import { getClonedNode, getPdfConverterOptions } from "../../../../utils/pdf";

import Close from "../../../../icon/Close";
import Upload from "../../../../icon/Upload";
import logo from "./images/logo.svg";

const ModalViewIpq = ({ activeUserData, onCancel, open }) => {
  const prospectObjective = activeUserData?.valueMap?.prospectObjective;
  const guideInitialData = activeUserData?.valueMap?.guideInitialData;

  const handleDownload = () => {
    const reportPageIpq1 = getClonedNode(
      document.getElementById("reportPageIpq1")
    );
    const reportPageIpq2 = getClonedNode(
      document.getElementById("reportPageIpq2")
    );

    html2pdf()
      .set(getPdfConverterOptions())
      .from(reportPageIpq1)
      .toContainer()
      .toCanvas()
      .toPdf()
      .get("pdf")
      .then(pdf => pdf.addPage())
      .set(getPdfConverterOptions())
      .from(reportPageIpq2)
      .toContainer()
      .toCanvas()
      .toPdf()
      .output("bloburl")
      .then(function (pdf) {
        let pdfWindow = window.open("", "_blank");
        pdfWindow.document.write(
          "<iframe  width='100%' height='100%' src='" + pdf + "'></iframe>"
        );
        pdfWindow.document.title = "OneAscent IPQ PDF";
      });
  };

  const Header = () => (
    <header
      style={{
        alignItems: "end",
        borderBottom: "1px solid #bec8ce",
        display: "flex",
        fontSize: 10,
        justifyContent: "space-between",
        lineHeight: "14px",
        paddingBottom: 9,
      }}
    >
      <Image preview={false} src={logo} width={136} />
      <div
        style={{ alignItems: "end", display: "flex", flexDirection: "column" }}
      >
        <b>Personalized Investment Proposal</b>
        <span style={{ textTransform: "capitalize" }}>
          {activeUserData?.firstName} {activeUserData?.lastName}
          {guideInitialData?.isMarried
            ? " & " +
              guideInitialData.spouseFirstName +
              " " +
              guideInitialData.spouseLastName
            : ""}
        </span>
        <span>
          {dayjs(
            guideInitialData?.startDate ??
              prospectObjective?.proposalSavedDate?.$date ??
              activeUserData?.updateDateTime?.$date
          ).format("MM/DD/YYYY")}
        </span>
      </div>
    </header>
  );

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify="space-between" wrap="wrap">
          <Button onClick={onCancel} shape="round" type="text">
            Close
          </Button>
          <Button
            icon={<Upload />}
            onClick={handleDownload}
            shape="round"
            type="custom"
          >
            Download IPQ
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="IPQ"
      width={1270}
    >
      <Flex gap={32} vertical>
        <ReportPageIpq1 prospectObjective={prospectObjective}>
          <Header />
        </ReportPageIpq1>
        <ReportPageIpq2 prospectObjective={prospectObjective}>
          <Header />
        </ReportPageIpq2>
      </Flex>
    </StyledModal>
  );
};

export default ModalViewIpq;
