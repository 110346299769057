import { useContext, useState } from "react";
import State from "../../../../../../context";
import { Button, Flex, Skeleton } from "antd";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalLevelGoalSettings from "../ModalLevelGoalSettings";
import ProposalGoalPreview from "../ProposalGoalPreview";

import StylesContainer from "./style";

import {
  PROPOSAL_DEFAULT_DATA_LEVEL,
  PROPOSAL_DEFAULT_LEVEL_PRODUCT_ID,
} from "../../../../constants";

import {
  archiveProposalGoal,
  restoreProposalGoal,
} from "../../../../../../utils/request/manager";
import { isLockedByAdminView } from "../../../../../../utils/helper/specialized";
import { getLiveAssessment } from "../../../../../../utils/request/regularApp";

import { ReactComponent as ActionsButton } from "../../../../../../icon/actions_menu.svg";
import { ReactComponent as IconPlus } from "../../../../images/icon_plus_outlined.svg";

const ViewProposalGoals = ({
  handleCopyGoal,
  handleDownloadProposal,
  handleViewGoal,
}) => {
  const [state] = useContext(State);
  const [showArchivedGoals, setShowArchivedGoals] = useState(false);

  const getActions = () => [
    {
      key: "1",
      label: showArchivedGoals ? (
        <div onClick={() => setShowArchivedGoals(false)}>
          Hide archived goals
        </div>
      ) : (
        <div onClick={() => setShowArchivedGoals(true)}>
          Show archived goals
        </div>
      ),
      disabled:
        !state.getPreferenceValue("productMapArchive") ||
        !Object.keys(state.getPreferenceValue("productMapArchive"))?.length,
    },
  ];

  const handleAddNewGoal = data => {
    state.setKeyValue("loading", true);

    getLiveAssessment({
      objective: {
        ...PROPOSAL_DEFAULT_DATA_LEVEL,
        ...data,
        email: state._id,
        productId: PROPOSAL_DEFAULT_LEVEL_PRODUCT_ID,
      },
      organization: "level2",
      productId: PROPOSAL_DEFAULT_LEVEL_PRODUCT_ID,
      saveAction: "POST",
    })
      .then(() =>
        state.setUserData().then(() => {
          state.setKeyValue("openModalLevelGoalSettings", false);
          state.setKeyValue("loading", false);
          state.showSuccess("New Goal Added!");
        })
      )
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleArchiveGoal = goalId => {
    archiveProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal archived");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleRestoreGoal = goalId => {
    restoreProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal restored");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleDownloadProposalClick = activeGoal => {
    handleDownloadProposal(activeGoal);
  };

  return (
    <StylesContainer>
      <header>
        <h3>
          Level: <b>Investment Proposal</b>
        </h3>
      </header>
      <main>
        <Skeleton loading={state.loading}>
          <Flex
            align="end"
            className="goals-page-header"
            justify="space-between"
            style={{ marginBottom: 22, width: 456 }}
          >
            <h3>
              {state.personalInfo?.firstName} {state.personalInfo?.lastName}
            </h3>

            <Flex align="center" gap={16}>
              <Button
                disabled={isLockedByAdminView({
                  managerAccess: state.managerAccess,
                })}
                icon={<IconPlus />}
                onClick={() =>
                  state.setKeyValue("openModalLevelGoalSettings", true)
                }
                shape="round"
                style={{ height: 36 }}
                type="primary"
              >
                New Goal
              </Button>
              <MenuActions items={getActions()}>
                <Button
                  icon={<ActionsButton />}
                  shape="circle"
                  size="small"
                  style={{ background: "#E6F4EF", width: 32 }}
                  type="text"
                />
              </MenuActions>
            </Flex>
          </Flex>
          <Flex align="stretch" gap={40}>
            {Object.keys(state.getPreferenceValue("productMap")).map(
              (goalId, index) => (
                <ProposalGoalPreview
                  archivedGoals={state.getPreferenceValue("productMapArchive")}
                  goalsList={state.getPreferenceValue("productMap")}
                  handleArchiveGoal={handleArchiveGoal}
                  handleCardClick={handleCardClick}
                  handleCopyGoal={handleCopyGoal}
                  handleDownloadProposalClick={handleDownloadProposalClick}
                  handleRestoreGoal={handleRestoreGoal}
                  handleViewGoal={handleViewGoal}
                  key={index}
                  organization={state.organization?.name}
                  productData={{
                    ...state.getPreferenceValue("productMap")[goalId][
                      state.getPreferenceValue("productMap")[goalId].length - 1
                    ],
                    goalId,
                  }}
                  productsList={state.productsList}
                  showError={state.showError}
                />
              )
            )}
            {showArchivedGoals &&
              Object.keys(state.getPreferenceValue("productMapArchive")).map(
                (goalId, index) => (
                  <ProposalGoalPreview
                    archivedGoals={state.getPreferenceValue(
                      "productMapArchive"
                    )}
                    goalsList={state.getPreferenceValue("productMap")}
                    handleArchiveGoal={handleArchiveGoal}
                    handleCardClick={handleCardClick}
                    handleCopyGoal={handleCopyGoal}
                    handleDownloadProposalClick={handleDownloadProposalClick}
                    handleRestoreGoal={handleRestoreGoal}
                    handleViewGoal={handleViewGoal}
                    key={index}
                    organization={state.organization?.name}
                    productData={{
                      ...state.getPreferenceValue("productMapArchive")[goalId]
                        .data[
                        state.getPreferenceValue("productMapArchive")[goalId]
                          .data.length - 1
                      ],
                      goalId,
                    }}
                    productsList={state.productsList}
                    showError={state.showError}
                  />
                )
              )}
          </Flex>
        </Skeleton>
      </main>

      <ModalLevelGoalSettings
        onCancel={() => state.setKeyValue("openModalLevelGoalSettings", false)}
        onSuccess={handleAddNewGoal}
        open={state.openModalLevelGoalSettings}
      />
    </StylesContainer>
  );
};

export default ViewProposalGoals;
