import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Flex, Form, Upload } from "antd";

import StyledModal from "./style";

import Close from "../../../../icon/Close";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";

const ModalVbi = ({ handleSubmit, onCancel, open }) => {
  const [form] = Form.useForm();

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex gap={4}>
          <Button
            form="formUploadVbi"
            htmlType="submit"
            shape="round"
            style={{ background: "#D9F3F7", border: "unset" }}
          >
            Submit
          </Button>
          <Button shape="round" type="text">
            Close
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="VBI Screening"
    >
      <Flex gap={12} vertical>
        <section>
          <h3>Step 1</h3>
          <p>Download the VBI Screening template</p>
          <div className="download-template-container">VBI Template</div>
        </section>

        <section>
          <h3>Step 2</h3>
          <p>Make updates to the sheet.</p>
        </section>

        <section>
          <h3>Step 3</h3>
          <p>Submit your updated spreadsheet.</p>
          <Form
            form={form}
            id="formUploadVbi"
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item name="fileData">
              <Upload.Dragger
                beforeUpload={() => false}
                maxCount={1}
                name="fileData"
              >
                <Flex align="center" gap={9} justify="center" vertical>
                  <FontAwesomeIcon
                    icon={faArrowUpFromBracket}
                    style={{ color: "#B2B2B2", fontSize: 27 }}
                  />
                  <p>
                    Drag file or{" "}
                    <u style={{ color: "#4897D1" }}>
                      <b>browse</b>
                    </u>{" "}
                    to upload
                  </p>
                </Flex>
              </Upload.Dragger>
            </Form.Item>
          </Form>
        </section>
      </Flex>
    </StyledModal>
  );
};

export default ModalVbi;
