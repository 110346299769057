import { lazy } from "react";

const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const TemplatePic = lazy(() => import("../template/TemplatePic"));
const TemplateCalculator = lazy(() => import("../template/TemplateCalculator"));

export default {
  "/": TemplateCalculator,
  "/pic": TemplatePic,
  "*": PageNotFound,
};
