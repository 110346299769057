import { useEffect, useState } from "react";
import { Flex, Tabs, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionHistorical from "./components/SectionHistorical";
import SectionPlanning from "./components/SectionPlanning";
import SectionRisk from "./components/SectionRisk";

import StyledModal from "./style";

import { DEFAULT_WITHDRAWAL_LEVEL } from "../../../../utils/constant";
import { FILTER_TAG_COLOR_LIST } from "../../constants";

import {
  getCalculations,
  getLiveAssessment,
} from "../../../../utils/request/regularApp";
import { getPercentValue } from "../../../../utils/helper/general";

import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as InUseIcon } from "./image/in_use.svg";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";

const ModalLevelProductDetails = ({
  handleClose,
  investmentAssumptions,
  open,
  product,
  showError,
}) => {
  const [liveAssessment, setLiveAssessment] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!product || !open) return;

    setLoading(true);

    if (product?.productName === "initial") {
      getCalculations({
        currentScenario: "initial",
        incomeBarchart: true,
        client: "level2",
      }).then(data => {
        setLoading(false);
        setLiveAssessment({
          ...data,
          productId: product._id,
        });
      });
    } else {
      const requestBody = {
        organization: "level2",
        productId: product._id ?? product.productId,
        objective: {
          ...investmentAssumptions,
          ...product,
          withdrawalLevel: getPercentValue(
            product.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL
          ),
        },
      };

      getLiveAssessment(requestBody)
        .then(data => {
          setLoading(false);
          setLiveAssessment({
            ...data,
            productId: product._id,
          });
        })
        .catch(error => {
          setLoading(false);
          showError(error);
        });
    }
  }, [product, open]);

  const items = [
    {
      children: <SectionHistorical product={product} />,
      key: "historical",
      label: "Historical",
    },
    {
      children: product && (
        <SectionPlanning
          liveAssessment={liveAssessment}
          loading={loading}
          product={product}
        />
      ),
      key: "planning",
      label: "Planning",
    },
    {
      children: <SectionRisk />,
      key: "risk",
      label: "Risk",
    },
  ];

  return (
    <StyledModal
      closeIcon={null}
      footer={null}
      onCancel={handleClose}
      open={open}
      title={
        <Flex gap={8} vertical>
          <Flex justify="space-between">
            <Flex align="start" gap={4}>
              <h3>
                {product?.name ?? `${product?.riskTolerance} Growth Rate`}
              </h3>
              {product?.ticker && (
                <span style={{ fontSize: 24 }}>({product?.ticker})</span>
              )}
            </Flex>
            <Flex align="center" gap={32}>
              <FontAwesomeIcon
                icon={
                  product?.customFilter === "My Favorites"
                    ? faBookmarkSolid
                    : faBookmarkRegular
                }
                style={{ fontSize: 18 }}
              />
              <CloseOutlined
                onClick={handleClose}
                style={{ color: "#818181", fontSize: 24 }}
              />
            </Flex>
          </Flex>
          <Flex gap={8}>
            <Tag color="#D7D7D7" style={{ color: "#052C2B" }}>
              {product?.type}
            </Tag>
            <Tag
              color={
                FILTER_TAG_COLOR_LIST.find(
                  it => it.label === product?.broadCategory
                )?.color
              }
            >
              {product?.broadCategory}
            </Tag>
            <Tag color="#02494E" icon={<InUseIcon />}>
              In Use
            </Tag>
          </Flex>
        </Flex>
      }
      width={790}
    >
      <Tabs items={items} />
    </StyledModal>
  );
};

export default ModalLevelProductDetails;
