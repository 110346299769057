import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-header {
      border-bottom: 1px solid #ccc;
      margin-bottom: 32px;
      padding-bottom: 8px;

      .ant-tag {
        align-items: center;
        display: flex;
        font-family: Inter, sans-serif;
        gap: 4px;
        height: 24px;
        margin: unset;
        padding-left: 10px;
        padding-right: 10px;
      }

      h3 {
        color: #1e1919;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        line-height: 24px;
        margin: 0;
      }
    }

    .ant-modal-content {
      border-radius: 12px;
      padding: 32px;
    }

    .ant-modal-body {
      .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        display: none;
      }

      .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        background: #fff;
        border-radius: 23px;
      }

      .ant-tabs .ant-tabs-tab-btn {
        padding: 0 10px;
      }

      .ant-tabs .ant-tabs-nav {
        &::before {
          display: none;
        }

        .ant-tabs-nav-wrap {
          justify-content: center;
        }
      }

      .ant-tabs-nav-list {
        background: #1e1919;
        border-radius: 23px;
        padding: 6px 8px;
      }

      .ant-tabs-tab {
        color: #fff;
        margin: unset;
        padding: unset;
      }
    }
  }

  h4 {
    color: #1e1919;
    font-size: 19px;
    line-height: 140%;
    margin: 0 0 8px;
  }

  .value-container {
    background: #fbfbfb;
    border: 1px solid #c8e4ff;
    border-radius: 12px;
    color: #1e1919;
    padding: 16px;

    span {
      line-height: 140%;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin: 0;
    }
  }
`;

export default StyledModal;
